import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PostAddIcon from "@material-ui/icons/PostAdd";
import "../css/welcome.css";
import { Carousel, Image } from "react-bootstrap";
import HomeBannerImage from "../../../img/homeimages/mockup.png";
import AppBannerImage from "../../../img/homeimages/thankynote-preview.png";
import Dalai from "../../../img/homeimages/dalailama.jpg";
import Oprah from "../../../img/homeimages/oprah.png";
import Eckhart from "../../../img/homeimages/eckhart.png";
import Kennedy from "../../../img/homeimages/kennedy.png";
import Footer from "../../common/footer";
import DownloadApp from "../../common/downloadApp";
import CarouselQuotes from "../../common/carouselQuotes";
import AuthSuggestBlock from "../../common/authSuggestBlock";
// import MissionBlock from "../../common/missionBlock";
// import ReloadBar from "../../common/reloadBar";
import { getLatestVersion } from "../../../services/getversion";
import { enableWebVersion } from "../../../services/settings";
import SamplePrivateNotes from "../../common/samplePrivateNotes";
import ReloadModal from "../../common/reloadModal";

const WelcomePage = ({ ...props }) => {
  const [version, setVersion] = useState(null);
  const params = props.match.params;
  let messageid = null;
  if (params) {
    messageid = params?.messageid || "";
  }
  let el = document.querySelector("title");
  const title = `Welcome to ThankyNote`;
  const content = `Join now. Start and your day with gratitude!`;
  el.innerText = title;
  el = document.querySelector("meta[name='description']");
  el.setAttribute("content", content);
  useEffect(() => {
    //call getLatestVersion() and set the state of version
    const getVersion = async () => {
      const version = await getLatestVersion();
      setVersion(version);
    };
    getVersion();
  }, []);

  return (
    <div>
      {version && <ReloadModal version={version} />}
      <div className="container col-lg-6 mt-4 pt-0">
        <div className="row align-items-center d-flex pt-0">
          <div className="text-center container h-50 pb-0 mb-0">
            <h1 className="mt-3 font-weight-bold text-primary">
              <div className="">
                <PostAddIcon className="logo-big" />
              </div>
              THANKY<span className="text-muted">NOTE</span>
            </h1>
            {/* <p className="defaultfontsize pb-4 text-black">
              Gratitude Made Easy{" "}
              <span role="img" aria-label="magic">
                ✨
              </span>
            </p> */}
            <h4 className="text-dark font-weight-light pt-5 pb-4">
              Start and end your day with gratitude.
            </h4>
            {enableWebVersion() ? (
              <div style={{ width: "280px" }} className="mx-auto text-center">
                <div className="mt-4 mb-0">
                  <div className="mb-4">
                    <Link
                      className="font-weight-bold btn-primary btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
                      to={`/register/${messageid}`}>
                      Start now, it's FREE
                    </Link>
                  </div>
                  <Link
                    className="font-weight-bold btn btn-outline-primary btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
                    to={`/login/${messageid}`}>
                    Log In
                  </Link>
                  <Link
                    className="font-weight-bold btn btn-link btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
                    to={`/forgot/${messageid}`}>
                    Forgot Password?
                  </Link>
                  <div className="mb-3">OR</div>
                  <DownloadApp type="download" />
                </div>
              </div>
            ) : (
              <div className="py-2">
                <DownloadApp type="download" css="py-4" size={200} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="pt-4">
        <hr />
      </div>
      <div className="px-4 d-none d-md-block">
        <SamplePrivateNotes entries={27} />
      </div>
      <div className="px-4 d-block d-md-none">
        <SamplePrivateNotes entries={9} />
      </div>
      <div className="text-center pt-4">
        <Link to="/inspirations" className="text-center">
          Click to see more inspirations
        </Link>
      </div>
      <div className="container col-lg-6 mt-4 pt-0">
        <div className="row align-items-center d-flex pt-0">
          <div className="text-center container h-50 pb-0 mb-0">
            {!enableWebVersion() && (
              <div>
                <div className="pt-4">
                  <hr />
                </div>
                <h2 className="pt-4 pb-3">How it looks?</h2>
                <div className="px-3 mb-4 roundcornernocolor responsive shadow">
                  <img
                    className="responsive img-fluid"
                    src={AppBannerImage}
                    alt="ThankyNote Home Page"
                  />
                </div>
              </div>
            )}
            {enableWebVersion() ? (
              <div>
                <h2 className="pt-4">For Web and Android</h2>
                <div className="px-3 mb-4 responsive">
                  <img
                    className="responsive img-fluid"
                    src={HomeBannerImage}
                    alt="ThankyNote Home Page"
                  />
                </div>
                <div className="pb-4">
                  <div className="mt-4 mb-0">
                    <div className="mb-4">
                      <Link
                        className="font-weight-bold btn-primary btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
                        to={`/register/${messageid}`}>
                        Try now, it's FREE
                      </Link>
                    </div>
                    <Link
                      className="font-weight-bold btn btn-outline-primary btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
                      to={`/login/${messageid}`}>
                      Log In
                    </Link>
                    <Link
                      className="font-weight-bold btn btn-link btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
                      to={`/forgot/${messageid}`}>
                      Forgot Password?
                    </Link>
                    <div className="mb-3">OR</div>
                    <DownloadApp type="download" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="pt-3 pb-4">
                <hr />
              </div>
            )}
            <CarouselQuotes />
            {/* <div className="py-4">
              <hr />
            </div>
            <div className="px-2">
              <MissionBlock />
            </div> */}
            <div className="py-4">
              <hr />
            </div>
            <div className="pb-4 pt-0 mb-4">
              <span
                role="img"
                aria-label="sparkle"
                style={{ fontSize: "80px" }}>
                ✨
              </span>
              <Carousel variant="dark" fade>
                <Carousel.Item>
                  <div
                    className="d-block w-100"
                    style={{
                      paddingLeft: "40px",
                      paddingRight: "40px",
                    }}>
                    <p className="text-dark py-4">
                      "When you practice gratefulness, there is a sense of
                      respect towards others."
                      <br />
                      <br />
                      <Image
                        src={Dalai}
                        width="30px"
                        height="30px"
                        alt=""
                        roundedCircle
                        style={{
                          zIndex: "1",
                          marginRight: "5px",
                          marginTop: "0px",
                        }}
                      />
                      Dalai Lama
                    </p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div
                    className="d-block w-100"
                    style={{
                      paddingLeft: "40px",
                      paddingRight: "40px",
                    }}>
                    <p className="text-dark py-4">
                      "Be thankful for what you have, you'll end up having
                      more."
                      <br />
                      <br />
                      <Image
                        src={Oprah}
                        width="30px"
                        height="30px"
                        alt=""
                        roundedCircle
                        style={{
                          zIndex: "1",
                          marginRight: "5px",
                          marginTop: "0px",
                        }}
                      />
                      Oprah Winfrey
                    </p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div
                    className="d-block w-100"
                    style={{
                      paddingLeft: "40px",
                      paddingRight: "40px",
                    }}>
                    <p className="text-dark py-4">
                      "We must find time to stop and thank the people who make a
                      difference in our lives."
                      <br />
                      <br />
                      <Image
                        src={Kennedy}
                        width="30px"
                        height="30px"
                        alt=""
                        roundedCircle
                        style={{
                          zIndex: "1",
                          marginRight: "5px",
                          marginTop: "0px",
                        }}
                      />
                      John F. Kennedy
                    </p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div
                    className="d-block w-100"
                    style={{
                      paddingLeft: "40px",
                      paddingRight: "40px",
                    }}>
                    <p className="text-dark py-4">
                      "Acknowledging the good that you already have in your life
                      is the foundation for all abundance."
                      <br />
                      <br />
                      <Image
                        src={Eckhart}
                        width="30px"
                        height="30px"
                        alt=""
                        roundedCircle
                        style={{
                          zIndex: "1",
                          marginRight: "5px",
                          marginTop: "0px",
                        }}
                      />
                      Eckhart Tolle
                    </p>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>

            <div className="py-4">
              <hr />
            </div>

            {enableWebVersion() ? (
              <AuthSuggestBlock messageid={messageid} />
            ) : (
              <div className="pt-4 pb-2">
                <DownloadApp type="download" css="py-4" size={200} />
              </div>
            )}
            <div className="my-4 py-4">
              <hr />
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
