import fire from "./fire";
import { isGod, isThankyNote } from "./settings";

export async function sendPushNotifications(
  type,
  sender,
  receiver,
  item,
  reply,
  token
) {
  //make sure sender.id === thankynote or God
  if ((isGod(sender.id) || isThankyNote(sender.id)) && token?.trim() !== "") {
    //cut reply to 75 characters if more than 75, else use the whole reply
    let trimmed = reply.trim();
    if (reply.length > 75) {
      trimmed = reply.substring(0, 75) + "...";
    }
    //get token
    const pushServer = fire.functions("asia-east2").httpsCallable("pushServer");
    console.log("Trigger pushServer...");
    const obj = {
      type: "token",
      tokens: [token],
      notification: {
        title: sender.name,
        body: trimmed,
      },
    };

    const out = await pushServer({ obj });
    console.log(out);
  } else {
    console.log("Not ThankyNote or God");
  }
}

export async function sendNotifications(type, sender, receiver, item) {
  //Somebody has replied to your ThankyNote: "Thank you Labsy for taking..."
  // console.log(sender);
  // console.log(receiver);
  // console.log(item);
  const db = fire.firestore();
  let sendername = "Somebody";
  let senderimagelink = "";
  let senderid = "";
  if (sender) {
    sendername = sender.name;
    senderid = sender.id;
    senderimagelink = sender.photo;
  }
  let subject;
  if (type === "notereply") {
    subject = `${sendername} has replied to your ThankyNote`;
  } else if (type === "notesent") {
    subject = `${sendername} has sent you a ThankyNote`;
  } else if (type === "notesaved") {
    subject = `${sendername} has saved your ThankyNote`;
  }
  const notificationsRef = db.collection(`notifications`).doc();
  const notificationObj = {
    type: type,
    receivername: receiver.name,
    receiverid: receiver.id,
    receiverimagelink: receiver.photo || "",

    subject: subject,
    content: item.content,
    messageid: item.id,

    sendername: sendername,
    senderid: senderid,
    senderimagelink: senderimagelink,

    publiclink: `https://thankynote.com/p/${item.id}`,
    timestamp: new Date().getTime(),
    open: false,
  };
  // console.log({ notificationObj });
  await notificationsRef.set(notificationObj);
}

export async function openNotifications(item) {
  //Somebody has replied to your ThankyNote: "Thank you Labsy for taking..."
  // console.log(item);
  const db = fire.firestore();

  await db.doc(`notifications/${item.id}`).update({
    open: true,
  });
}

export async function getNotifications(uid) {
  const db = fire.firestore();
  try {
    const count = uid !== "eZTw1KEQEMQ5mLEiupUeonn8vw23" ? 20 : 30;
    const notificationObj = await db
      .collection(`notifications`)
      .where("receiverid", "==", uid)
      .orderBy("timestamp", "desc")
      .limit(count)
      .get();
    const notifications = [];
    notificationObj.forEach((doc) => {
      const data = doc.data();
      notifications.push({ ...data, id: doc.id });
    });
    return notifications; //.sort(compare);
    // return notifications.sort(compare);
  } catch (e) {
    console.log({ e });
  }

  return null;
}
