import React from "react";

function NoFriends() {
  return (
    <div className="pb-4 my-4">
      <span style={{ fontSize: "40px" }} role="img" aria-label="Nothing">
        🤷‍♂️
      </span>
      <p>Share ThankyNote with your friends.</p>
    </div>
  );
}

export default NoFriends;
