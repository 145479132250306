import React, { useEffect, useState } from "react";
import { getLatestVersion } from "../../../services/getversion";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import Header from "../../common/header";
// import ReloadBar from "../../common/reloadBar";
import DownloadApp from "../../common/downloadApp";
import MissionBlock from "../../common/missionBlock";
import CarouselQuotes from "../../common/carouselQuotes";
import AuthSuggestBlock from "../../common/authSuggestBlock";
import Footer from "../../common/footer";
import Confetti from "react-confetti";
import { Link } from "react-router-dom";
import HomeBannerImage from "../../../img/homeimages/mockup.png";
import SamplePrivateNotes from "../../common/samplePrivateNotes";
import { enableWebVersion } from "../../../services/settings";
import ReloadModal from "../../common/reloadModal";

function Inspirations() {
  const [version, setVersion] = useState("");

  useEffect(() => {
    const getDatas = async () => {
      const result = await getLatestVersion();

      setVersion(result);
    };
    getDatas();
    window.scrollTo(0, 0);
  }, []);

  if (version === "") {
    return (
      <LoadingSpinnerNav
        hidehome={true}
        hide="profile"
        hidefriends={true}
        hidenotifications={true}
      />
    );
  }

  return (
    <div>
      <Header hide="profile" />
      <ReloadModal version={version} />

      <Confetti recycle={false} opacity={1} gravity={0.1} />
      <div className="row justify-content-center mx-0">
        <div className="col-lg-8 pt-4">
          <DownloadApp type="simple" />
        </div>
      </div>
      <div className="px-4">
        <SamplePrivateNotes />
      </div>
      <div className="container col-lg-6 mt-4 pt-0">
        <div className="row align-items-center d-flex pt-0">
          <div className="text-center container h-50 pb-0 mb-0">
            {enableWebVersion() && (
              <div>
                <h2 className="pt-4">For Web and Android</h2>
                <div className="px-3 mb-4 responsive">
                  <img
                    className="responsive img-fluid"
                    src={HomeBannerImage}
                    alt="ThankyNote Home Page"
                  />
                </div>
                <div className="pb-4">
                  <div className="mt-4 mb-0">
                    <div className="mb-4">
                      <Link
                        className="font-weight-bold btn-primary btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
                        to={`/register`}>
                        Try now, it's FREE
                      </Link>
                    </div>
                    <Link
                      className="font-weight-bold btn btn-outline-primary btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
                      to={`/login`}>
                      Log In
                    </Link>
                    <Link
                      className="font-weight-bold btn btn-link btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
                      to={`/forgot`}>
                      Forgot Password?
                    </Link>
                    <div className="mb-3">OR</div>
                    <DownloadApp type="download" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!enableWebVersion() && (
        <div className="row justify-content-center mx-0">
          <div className="col-lg-8 pt-0">
            <div className="text-center py-0 mt-4">
              <div className="py-0">
                <div className="pt-4 pb-5">
                  <hr />
                </div>
                <DownloadApp type="download" css="py-4" size={200} />
                <div className="pt-5 pb-0">
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row justify-content-center mx-0">
        <div className="col-lg-8 pt-4">
          <div className="text-center pt-4 mt-4">
            <MissionBlock />
            <div className="py-4">
              <hr />
            </div>
            <CarouselQuotes />
            <div className="my-4 py-4">
              <hr />
            </div>
            {enableWebVersion() ? (
              <AuthSuggestBlock />
            ) : (
              <div className="pt-3 pb-2">
                <DownloadApp type="download" css="py-4" size={200} />
              </div>
            )}
          </div>
          <div className="my-4 py-4">
            <hr />
          </div>
        </div>
      </div>

      <div className=" text-center">
        <Footer />
      </div>
    </div>
  );
}

export default Inspirations;
