import React, { Component } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import Header from "../../common/header";
import { getCurrentUserData, userLogout } from "../../../services/user";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import MyProfileCardPanel from "../../blocks/myProfileCardPanelBlock";
import LinkButton from "../../blocks/linkButtonBlock";
import { logEvent } from "../../../services/log";
import {
  enableEditBasicProfile,
  enableProfilePhotoUpload,
} from "../../../services/settings";
import { isValidFullname } from "../../../services/validation";
import SystemBarError from "../../common/systemBarError";
import ProfileFullname from "../../profile/component/profileFullname";
import ProfileVerifyStatus from "../../profile/component/profileVerifyStatus";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { Grid, Slider } from "@material-ui/core";
import AvatarEditor from "react-avatar-editor";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import fire from "../../../services/fire";
import { updateProfilePic } from "../../../services/profile";
import SpinnerText from "../../common/spinnerwithText";
import SuccessMessage from "../../common/successMessageBox";
import { Link } from "react-router-dom";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import { getLatestVersion } from "../../../services/getversion";
// import ReloadBar from "../../common/reloadBar";
import DisplayStreak from "../../common/displayStreak";
import { convertFireStorageUrl } from "../../../utils/url";
import DebugElements from "../../debug/component/debugElements";
import ReloadModal from "../../common/reloadModal";

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        fullname: "",
        email: "",
        storecontact: "",
        storelink: "",
        storename: "",
        streak: [],
      },
      user: this.props.user,
      imageDataUrl: "",
      showphotoedit: false,
      saving: false,
      loading: true,
      zoom: 1,
    };
  }

  async getCurrentUserData() {
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const {
        totalnotes,
        totalreceived,
        totalsent,
        fullname,
        email,
        storecontact,
        storelink,
        storename,
        bigpic,
        country,
        streak,
      } = user;

      let isvalidfullname = isValidFullname(fullname);

      const photoUrl = bigpic;
      let el = document.querySelector("title");
      const title = `${fullname} Profile | ThankyNote`;
      const content = `Together we spread gratitude around the world!`;
      el.innerText = title;
      el = document.querySelector("meta[name='description']");
      el.setAttribute("content", content);
      let version = await getLatestVersion();

      this.setState({
        version,
        isvalidfullname,
        totalnotes,
        totalreceived,
        totalsent,
        data: {
          fullname,
          email,
          storecontact,
          storelink,
          storename,
          country,
          streak,
        },
        photoUrl,
        loading: false,
      });
    } else {
      await userLogout();
    }
  }

  componentDidMount() {
    this.getCurrentUserData();
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      logEvent("logout_click", { email: data.email });
      await userLogout();

      // signed out
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  doEdit = () => {
    this.props.history.push("/account/edit");
  };

  onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const { data } = this.state;
      const file = e.target.files[0];
      let imageDataUrl = await this.readFile(file);

      logEvent("profile_new_photo_upload", { email: data.email });

      this.setState({ imageDataUrl, showphotoedit: true });
    }
  };

  readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  onClickCancel = () => {
    this.setState({ showphotoedit: false });
  };

  onClickSave = async () => {
    if (this.editor) {
      const { data } = this.state;

      this.setState({ saving: true });
      const canvasScaled = this.editor.getImageScaledToCanvas();
      const userid = this.state.user.uid;
      const uploadpath = `images/${userid}`;
      const filename = `${userid}.jpg`;

      fire
        .storage()
        .ref(uploadpath)
        .child(filename)
        .putString(canvasScaled.toDataURL(), "data_url")
        .then(async (snapshot) => {
          await updateProfilePic(userid, filename);
          logEvent("profile_photo_changed_success", { email: data.email });

          this.setState({
            newprofileupload: true,
            saving: false,
            showphotoedit: false,
            photoUrl: canvasScaled.toDataURL(),
          });
        });
    }
  };

  setEditorRef = (editor) => (this.editor = editor);

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  increaseZoom = () => {
    const zoom = this.state.zoom;
    let newzoom = 3;
    if (zoom < 3) {
      newzoom = zoom + 0.1;
    }
    this.setState({ zoom: newzoom });
  };

  decreaseZoom = () => {
    const zoom = this.state.zoom;
    let newzoom = 1;
    if (zoom > 1) {
      newzoom = zoom - 0.1;
    }
    this.setState({ zoom: newzoom });
  };

  reloadProfile = () => {
    window.location.reload();
  };

  goHome = () => {
    this.props.history.push("/home");
  };
  render() {
    const {
      data,
      loading,
      isvalidfullname,
      photoUrl,
      zoom,
      showphotoedit,
      imageDataUrl,
      saving,
      newprofileupload,
      totalnotes,
      totalreceived,
      totalsent,
      version,
    } = this.state;

    const enablePicUpload = enableProfilePhotoUpload();
    const { fullname } = data;

    if (loading === true) {
      return (
        <LoadingSpinnerNav
          type="backandlogo"
          hidehome
          hidefriends
          hide="profile"
        />
      );
    }

    let showedit = false;
    if (enableEditBasicProfile()) {
      showedit = true;
    }
    if (newprofileupload) {
      return (
        <SuccessMessage
          message="Your photo has been successfully saved!"
          label="Done"
        />
      );
    }
    let showstreak = false;
    let daylabel = [];

    if (data?.streak) {
      const obj = data?.streak;
      if (obj && obj.length > 0) {
        daylabel = [
          { id: 1, label: "S", data: obj[0] },
          { id: 2, label: "M", data: obj[1] },
          { id: 3, label: "T", data: obj[2] },
          { id: 4, label: "W", data: obj[3] },
          { id: 5, label: "T", data: obj[4] },
          { id: 6, label: "F", data: obj[5] },
          { id: 7, label: "S", data: obj[6] },
        ];
        showstreak = true;
      }
    }
    // showstreak = false;
    return (
      <React.Fragment>
        <Header type="backandlogo" hidehome hidefriends hide="profile" />

        <ReloadModal version={version} />

        {!isvalidfullname && (
          <SystemBarError
            text="Please update your Full Name."
            link="/account/edit"
          />
        )}
        <div className="row justify-content-center mx-auto appearslowly">
          <div className="col-lg-6 text-center mx-auto">
            <main className="container text-center mx-auto ">
              {!showphotoedit && (
                <React.Fragment>
                  <nav className="navbar navbar-light flex-column">
                    <h1 className="mx-auto mt-4 text-black-50 text-center">
                      {!enablePicUpload && (
                        <AccountCircleTwoToneIcon
                          className="text-primary"
                          style={{ fontSize: "100px" }}
                        />
                      )}
                      {enablePicUpload && (
                        <React.Fragment>
                          {!photoUrl && (
                            <AccountCircleTwoToneIcon
                              className="text-primary"
                              style={{ fontSize: "100px" }}
                            />
                          )}
                          {photoUrl && (
                            <Image
                              src={convertFireStorageUrl(photoUrl)}
                              width="100px"
                              height="100px"
                              alt=""
                              roundedCircle
                            />
                          )}
                          <div className="image-upload">
                            <label
                              htmlFor="file-input"
                              className="image-upload-icon">
                              <AddAPhotoIcon />
                            </label>

                            <input
                              id="file-input"
                              type="file"
                              onChange={this.onFileChange}
                              accept="image/*"
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </h1>
                  </nav>
                  <ProfileFullname
                    isvalidfullname={true}
                    fullname={data.storename || fullname}
                  />
                  {data.storelink && (
                    // <a
                    //   target="_blank"
                    //   rel="noopener noreferrer"
                    //   href={`https://palitna.com/${data.storelink}`}
                    // >
                    //   <p>{`palitna.com/${data.storelink}`}</p>
                    // </a>
                    <div className="my-2">
                      <Link to="/home">Upload Product Photos</Link>
                    </div>
                  )}

                  <h4 className="text-left pt-4 mt-2 text-primary">Stats</h4>
                  {/* <hr /> */}
                  <div
                    className="card roundcornernocolor mb-0 pt-3 pb-0 pointer text-center"
                    onClick={this.goHome}>
                    <Row className="mx-1">
                      <Col>
                        <h2 className="py-0 my-0 text-center text-muted">
                          {totalnotes || 0}
                        </h2>
                        <p className="text-center text-muted py-0">NOTES</p>
                      </Col>
                      <Col>
                        <h2 className="py-0 my-0 text-center text-muted">
                          {totalsent || 0}
                        </h2>

                        <p className="text-center text-muted py-0">SENT</p>
                      </Col>
                      <Col>
                        <h2 className="py-0 my-0 text-center text-muted">
                          {totalreceived || 0}
                        </h2>

                        <p className="text-center text-muted py-0">RECEIVED</p>
                      </Col>
                    </Row>
                  </div>
                  {/* <hr /> */}
                  <h4 className="text-left pt-4 mt-3 text-primary">
                    Activities
                  </h4>
                  {/* <hr /> */}
                  {showstreak && (
                    <div className="card roundcornernocolor">
                      <div className="row p-4">
                        {daylabel.map((item) => (
                          <div key={item.id} className="col text-center px-0">
                            {/* {this.displayData(item.label, item.data)} */}
                            <DisplayStreak
                              label={item.label}
                              data={item.data}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {!showstreak && (
                    <div className="card roundcornernocolor text-center py-4 text-muted">
                      No Activities Yet
                    </div>
                  )}
                  {/* <hr /> */}
                  <Row className="mt-4">
                    <Col className="text-left">
                      <h4 className="text-left pt-2 text-primary">Profile</h4>
                    </Col>
                    <Col className="text-right pt-3">
                      <ProfileVerifyStatus
                        showedit={showedit}
                        requiredstatus={true}
                        status={true}
                        // status={user.emailVerified}
                      />
                    </Col>
                  </Row>
                  <MyProfileCardPanel data={data} showchangelink={true} />
                  <div className="mt-4 pt-4">
                    <DebugElements />
                  </div>
                  <Button
                    variant="outline-primary"
                    className="btn-block btn-lg mt-4"
                    style={{ border: "1px solid #dddddd" }}
                    onClick={this.doSubmit}>
                    Logout
                  </Button>
                  <LinkButton type="link" link="/home" label="Back to Home" />
                </React.Fragment>
              )}
              {showphotoedit && (
                <div className="mx-auto">
                  <h4 className="text-left py-4">Update Photo</h4>
                  <div className="pb-4">
                    <AvatarEditor
                      ref={this.setEditorRef}
                      image={imageDataUrl}
                      width={250}
                      height={250}
                      border={0}
                      borderRadius={250}
                      scale={zoom}
                      crossOrigin="anonymous"
                    />
                  </div>
                  <Grid container spacing={2} className="my-2">
                    <Grid item>
                      <RemoveIcon onClick={this.decreaseZoom} />
                    </Grid>
                    <Grid item xs>
                      <Slider
                        className="text-primary"
                        value={this.state.zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => this.onZoomChange(zoom)}
                      />
                    </Grid>
                    <Grid item>
                      <AddIcon onClick={this.increaseZoom} />
                    </Grid>
                  </Grid>

                  {!saving && (
                    <Button
                      variant="primary"
                      className="btn-block btn-lg"
                      onClick={this.onClickSave}>
                      Save
                    </Button>
                  )}
                  {saving && (
                    <Button
                      variant="primary"
                      className="btn-block btn-lg"
                      disabled>
                      <SpinnerText text="Saving..." />
                    </Button>
                  )}
                  <Button
                    variant="link"
                    className="btn-block btn-lg text-primary"
                    onClick={this.onClickCancel}>
                    Cancel
                  </Button>
                </div>
              )}
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProfilePage;
