import React from "react";
import { Button } from "react-bootstrap";
import Joi from "joi-browser";
import Form from "../../common/form";
import Header from "../../common/header";
import fire from "../../../services/fire";
import ScrollToTop from "../../common/scrollToTop";
import LinkButton from "../../blocks/linkButtonBlock";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import WatchLaterOutlinedIcon from "@material-ui/icons/WatchLaterOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { getCurrentUserData, getCurrentUser } from "../../../services/user";

class ThankYouSetup extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        content: "",
      },
      owner: {
        fullname: "",
        email: "",
        mobile: "+65",
      },
      announcementlist: [],
      emailsent: false,
      errors: {},
      user: this.props.user,
      loading: true,
      submitting: false,
    };
  }

  schema = {
    content: Joi.string().required().label("Announcement"),
  };

  async getAnnouncements() {
    //retrieve data from firestore
    const { userid } = this.state.owner;

    try {
      fire
        .firestore()
        .collection("notes")
        .where("authorid", "==", userid)
        .orderBy("created", "desc")
        .get()
        .then((querySnapshot) => {
          var list = [];
          querySnapshot.forEach((doc) => {
            list.push(doc.data());
          });
          this.setState({
            announcementlist: list,
            loading: false,
          });
        });
    } catch (error) {
      console.error("Announcements not yet created");
      this.setState({ loading: false });
    }
  }

  async getCurrentUserData() {
    //retrieve data from firestore
    try {
      const uid = this.state.user.uid;
      const user = await getCurrentUserData(uid);

      if (user) {
        const { fullname, email, mobile, userid } = user;
        // if (!parish) {
        //   this.props.history.push("/account/setup");
        //   return;
        // }
        // const parishcode = getParishLinkById(parish);

        this.setState({
          user,
          owner: {
            userid,
            fullname,
            email,
            mobile,
          },
        });
        await this.getAnnouncements();
      }
    } catch (error) {
      console.error("User not yet created");
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    // let curUser = await getCurrentUser();
    if (1) {
      //isSuperAdminRole(curUser) || isParishPriestRole(curUser)) {
      await this.getCurrentUserData();
      window.scrollTo(0, 0);
    } else {
      this.props.history.push("/home");
      return;
    }
  }

  doSubmit = async () => {
    // Call the server
    this.setState({ submitting: true });
    const { announcementlist } = this.state;
    const { fullname, userid } = this.state.owner;
    const { content } = this.state.data;
    const uniqNum = new Date().getTime();

    try {
      const announcementref = fire.firestore().collection("notes").doc();

      var docData = {
        id: announcementref.id,
        authorid: userid,
        author: fullname,
        created: uniqNum,
        content,
      };

      await announcementref.set(docData);

      let list = [docData, ...announcementlist];

      this.setState({
        announcementlist: list,
        submitting: false,
        data: {
          content: "",
        },
      });
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  handleDelete = async (id) => {
    const { userid } = this.state.owner;
    try {
      var announcement = await fire
        .firestore()
        .collection("notes")
        .where("authorid", "==", userid)
        .where("id", "==", id)
        .get();

      announcement.forEach((doc) => {
        doc.ref.delete();
      });

      const { announcementlist } = this.state;
      let updatedannouncements = announcementlist.filter(
        (item) => item.id !== id
      );

      this.setState({
        announcementlist: updatedannouncements,
        announcementcount: updatedannouncements.length,
        submitting: false,
        data: {
          content: "",
        },
      });
    } catch (error) {
      console.log("Unable to Delete:", error);
      this.setState({
        submitting: false,
      });
    }
  };

  render() {
    const { submitting, loading, announcementlist, owner, user } = this.state;

    if (loading === true) {
      return (
        <LoadingSpinnerNav
          user={user}
          smallpic={user.photoURL}
          hidehome={true}
          hide={true}
        />
      );
    }

    return (
      <React.Fragment>
        <Header
          user={user}
          smallpic={user.photoURL}
          hide={true}
          hidehome={true}
        />
        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-6">
            <main className="container">
              <div className="pb-3 pt-4">
                <h1 className="pb-2 ">HOME</h1>

                <form onSubmit={this.handleSubmit}>
                  {this.renderTextarea(
                    "content",
                    "",
                    "text",
                    "What are the things you are thankful for in your life?"
                  )}

                  {!submitting && this.renderBlockButton("Post")}
                  {submitting && this.renderLoadingBlockButton("Posting...")}
                </form>

                <div id="announcementsection" className="my-4 py-4">
                  {announcementlist.map((list) => (
                    <div
                      className="alert alert-primary announcementpanelblue"
                      role="alert"
                      key={list.id}
                    >
                      <p className="mb-0">{list.content}</p>
                      <div className="text-muted">
                        <PersonOutlinedIcon style={{ fontSize: "14px" }} />{" "}
                        <small>{list.author}</small>
                        <div className="clearfix"></div>
                        <WatchLaterOutlinedIcon
                          style={{ fontSize: "12px" }}
                        />{" "}
                        <small>{new Date(list.created).toDateString()}</small>
                      </div>
                      <Button
                        variant="outline-secondary"
                        className="float-right btn-sm"
                        onClick={() => this.handleDelete(list.id)}
                      >
                        <div className="float-left" style={{ width: "25px" }}>
                          <DeleteOutlineOutlinedIcon
                            style={{ fontSize: "18px", marginTop: "-2px" }}
                          />
                        </div>
                        <div className="float-left">Delete</div>
                      </Button>
                      <div className="clearfix"></div>
                    </div>
                  ))}
                </div>

                {announcementlist && announcementlist.length > 3 && (
                  <React.Fragment>
                    <ScrollToTop />
                    <LinkButton type="link" link="/home" label="Back to Home" />
                  </React.Fragment>
                )}
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ThankYouSetup;
