import React from "react";
import fire from "../../services/fire";

function TestBlock() {
  let test = false;
  if (!test) {
    return null;
  }
  return (
    <div className="d-flex justify-content-center">
      <button
        className="btn btn-primary"
        onClick={async () => {
          const pushServer = fire
            .functions("asia-east2")
            .httpsCallable("pushServer");
          console.log("Trigger pushServer...");
          const obj = {
            type: "token",
            tokens: [
              "eC8xHDY-TNesCbjsFVxWaB:APA91bF6QuE4T36tuRBIJ7o_eta-29w2asZsO9q809TjFMCd00g9brVV3nF0U633dJGyTZ2WywDNGrYtri0thvtJkE2MD0k4fSWYlyMhs7T5xzVcA-bhHlTXC-dpBS9ZYjxpKmw7ADOi",
            ],
            notification: {
              // title: "In His Image 🙏",
              // body: "Reflect His love in your marriage. Love unconditionally. 💖",
              title: "Latest myCatholicSG App Updates",
              body: `Dear Brothers and Sisters in Christ,

We've updated our myCatholicSG app to fix some bugs that you have reported.

Please update your app now to the latest versions.

Android: https://play.google.com/store/apps/details?id=com.CSG.CatholicSG

iOS: https://apps.apple.com/sg/app/catholicsg-app/id1151027240`,
            },
          };

          const out = await pushServer({ obj });
          console.log(out);
        }}>
        Run
      </button>
    </div>
  );
}

export default TestBlock;
