import React from "react";

function MissionBlock() {
  return (
    <div className="py-4">
      <span role="img" aria-label="fire" style={{ fontSize: "80px" }}>
        🔥
      </span>
      <h2 className="text-primary">Our Mission</h2>
      <h2 className="text-dark font-weight-light py-4">
        Spread gratitude, one person at a time.
      </h2>
    </div>
  );
}

export default MissionBlock;
