import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../common/loadingSpinner";
import { userLogout, getCurrentUserData } from "../../../services/user";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { logEvent } from "../../../services/log";

class RegisterSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        fullname: "",
        identification: "",
        email: "",
        dob: "",
        mobile: "+65",
        parish: 0,
        preferredmasstiming: "",
      },
      errors: {},
      parish: [],
      loading: true,
    };
  }

  async getCurrentUserData() {
    //retrieve data from firestore
    let uid = this.props.user.uid;
    const user = await getCurrentUserData(uid);
    if (user && user.exists) {
      const {
        fullname,
        email,
        mobile,
        dob,
        identification,
        parish,
        preferredmasstiming,
      } = user.data();
      this.setState({
        data: {
          fullname,
          email,
          mobile,
          dob,
          identification,
          parish,
          preferredmasstiming,
        },
        loading: false,
      });
      if (identification) {
        this.props.history.push("/account/setup");
      }
      /*
      potentially redirect to home if filled in already
      */
    } else {
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  }

  componentDidMount() {
    this.getCurrentUserData();
    window.scrollTo(0, 0);
  }

  render() {
    const { loading, data } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }
    logEvent("register_success", {
      email: data.email,
    });
    return (
      <React.Fragment>
        <div className="row justify-content-center mt-5  mx-auto">
          <div className="col-lg-4">
            <main className="container">
              <div className="pb-3 text-center">
                <h1 className="pb-2 mt-5">
                  <CheckCircleOutlineIcon
                    style={{ fontSize: "150px", color: "green" }}
                  />
                </h1>
                <h1 className="py-2">Account created!</h1>
                <h3 className="py-3">Let's setup your profile</h3>
                <Link
                  className="btn btn-lg roundcornernocolorbutton btn-primary btn-block"
                  to="/account/setup"
                  role="button">
                  Continue
                </Link>
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegisterSuccess;
