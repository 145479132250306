import React from "react";
import fire from "../../../services/fire";

function DebugElements() {
  const debug = false;
  const onClick = async () => {
    console.log("Running...");
    const replyAsGod = fire.functions("asia-east2").httpsCallable("replyAsGod");
    try {
      console.log("Calling replyAsGod...");
      const result = await replyAsGod({ content: "Hello World!" });
      if (result.data.status === 0) {
        console.log("Success...");
        console.log(`Reply: ${result.data.message}`);
      } else {
        console.log("Fail...");
      }
    } catch (err) {
      console.log("Error:", err);
    }
    console.log("End...");
  };

  return debug === true ? (
    <div className="pb-4 mb-4">
      <button className="btn btn-warning" onClick={onClick}>
        Run
      </button>
    </div>
  ) : null;
}

export default DebugElements;
