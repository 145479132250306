import React, { Component } from "react";
import Header from "../../common/header";
import fire from "../../../services/fire";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import { Table } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { getCurrentUser } from "../../../services/user";

class UserStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      loading: true,
    };
  }

  async componentDidMount() {
    //check if allowed
    const user = await getCurrentUser();
    if (!user) {
      this.props.history.push("/welcome");
      return;
    }
    if (
      user.uid === "mTdWaLm65nSWzXU1cHE4PmVmUPm1" ||
      user.uid === "VYj16iAHJJh5WVdimE5BfTvluN63"
    ) {
      await this.getStatsData();
    } else {
      this.props.history.push("/home");
      return;
    }
  }

  async getStatsData() {
    //retrieve data from firestore

    try {
      fire
        .firestore()
        .collection("users")
        .where("tokens", "!=", "")
        .get()
        .then(async (querySnapshot) => {
          const list = [];
          const users = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data?.test !== true && data?.tokens?.length > 0) {
              //get the last token
              const token = data.tokens[data.tokens.length - 1];
              list.push({ ...data, token: token });
            }
          });

          if (list.length) {
            console.log("List: ", list);
          }

          this.setState({
            users: list,
            loading: false,
          });
        });
    } catch (error) {
      console.error("Notes not yet created");
      this.setState({ loading: false });
    }
  }

  handleCopy = (entry) => {
    alert(`Token for ${entry.email} copied to clipboard!`);
  };

  render() {
    const { loading, users } = this.state;
    if (loading === true) {
      return <LoadingSpinnerNav hide="profile" />;
    }

    return (
      <React.Fragment>
        <Header hide="profile" />
        <div className="row justify-content-start  mx-4">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Total Notes</th>
                <th>Token</th>
              </tr>
            </thead>
            <tbody>
              {users.map((entry, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{entry.fullname}</td>
                    <td>{entry.email}</td>
                    <td>{entry.totalnotes}</td>
                    <td className="pointer">
                      <CopyToClipboard
                        text={entry.token}
                        onCopy={() => this.handleCopy(entry)}>
                        <div>{entry.token}</div>
                      </CopyToClipboard>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

export default UserStats;
