import React from "react";
import Joi from "joi-full";
import Form from "../../common/form";
import fire from "../../../services/fire";
import { withRouter } from "react-router-dom";
import { userLogout } from "../../../services/user";
import { logEvent } from "../../../services/log";
import LinkButton from "../../blocks/linkButtonBlock";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { isValidFullname } from "../../../services/validation";
import { Button } from "react-bootstrap";
import countryList from "react-select-country-list";

class UserDetailsEdit extends Form {
  constructor(props) {
    super(props);
    const { fullname, country } = this.props.userdetails;
    let isvalidfullname = isValidFullname(fullname);
    let errors = isvalidfullname
      ? {}
      : { fullname: "Valid Full Name only. No NRIC or email please." };
    this.state = {
      isvalidfullname,
      countries: countryList()
        .getData()
        .map((item) => {
          return { _id: item.value, name: item.label };
        }),
      data: {
        fullname: fullname || "",
        country: country || "",
        // storecontact: storecontact || "",
        // storelink: storelink || "",
        // storename: storename || "",
      },
      fullname,
      errors: errors,
      loading: false,
      profilesave: false,
    };
  }

  schema = {
    fullname: Joi.string().trim().required().label("Full Name"),
    country: Joi.string().trim().required().label("Country"),
    // storecontact: Joi.string().trim().required().label("Facebook Link"),
    // storelink: Joi.string().trim().required().label("PalitNa Store Link"),
    // storename: Joi.string().trim().required().label("PalitNa Store Name"),
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  doSubmitOrig = async () => {
    const { email, storelink } = this.props.userdetails;
    const { data } = this.state;
    const linkresult = data.storelink.match(/^([a-zA-Z0-9_-]+)$/);
    let err = {};

    this.setState({
      errors: err,
      loading: true,
    });
    if (!linkresult) {
      err = {
        storelink: "Only dash, underline and alphanumeric characters allowed.",
      };
      this.setState({
        errors: err,
        loading: false,
      });
      return;
    }

    // Call the server
    let newstorelink = data.storelink.replace(/\s+/g, "");
    if (newstorelink !== storelink) {
      try {
        const checkStoreLink = fire
          .functions("asia-east2")
          .httpsCallable("checkStoreLink");

        let result = await checkStoreLink({ input: newstorelink });
        let error;
        if (result.data.status !== 0) {
          if (result.data.status === 1) {
            error = {
              storelink: "Already taken. Please choose another value.",
            };
          } else if (result.data.status === 2) {
            error = { storelink: "Incorrect input." };
          } else {
            error = { storelink: "Unable to validate." };
          }

          this.setState({
            // data: {
            //   storelink: newstorelink.trim(),
            // },
            errors: error,
            loading: false,
          });
          return;
        }
      } catch (error) {
        console.log("Error:", error);

        this.setState({
          // data: {
          //   storelink: newstorelink.trim(),
          // },
          errors: error,
          loading: false,
        });
        return;
      }
    }

    try {
      const currentUser = fire.auth().currentUser;
      let contact = data.storecontact.replace(/\s/g, "");
      contact = contact.replace("https://www.facebook.com/", "");
      await fire.firestore().doc(`users/${currentUser.uid}`).update({
        fullname: data.fullname.trim(),
        storecontact: contact.toLowerCase().trim(),
        storelink: newstorelink.toLowerCase(),
        storename: data.storename.trim(),
      });

      //set state to refresh page
      this.setState({ profilesave: true });
    } catch (ex) {
      logEvent("user_account_update_fail", {
        email: email,
        message: ex.message,
      });
      console.error("Unable to Set Data");
    }
  };
  doSubmit = async () => {
    const { email } = this.props.userdetails;
    const { data } = this.state;
    let err = {};

    this.setState({
      errors: err,
      loading: true,
    });

    try {
      const currentUser = fire.auth().currentUser;

      await fire.firestore().doc(`users/${currentUser.uid}`).update({
        fullname: data.fullname.trim(),
        country: data.country.trim(),
      });
      await currentUser.updateProfile({
        displayName: data.fullname.trim(),
      });
      //set state to refresh page
      this.setState({ profilesave: true });
    } catch (ex) {
      logEvent("user_account_update_fail", {
        email: email,
        message: ex.message,
      });
      console.error("Unable to Set Data");
    }
  };

  forceLogout = async () => {
    try {
      await userLogout();
      // signed out
      window.location.reload();
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  render() {
    const { loading, profilesave, countries } = this.state;

    return (
      <div>
        {!profilesave && (
          <React.Fragment>
            <h3 className="py-3">Your Info</h3>

            <form onSubmit={this.handleSubmit}>
              {this.renderInput(
                "fullname",
                "Your NAME",
                "text",
                "Type your name"
              )}
              {this.renderSelect("country", "Your country", countries)}

              {!loading && this.renderBlockButton("Save")}
              {loading && this.renderLoadingBlockButton("Saving...")}
            </form>
            <Button
              variant="link"
              onClick={() => this.props.history.goBack()}
              className="btn-block btn-lg my-4"
            >
              Cancel
            </Button>
          </React.Fragment>
        )}

        {profilesave && (
          <React.Fragment>
            <div className="text-center">
              <CheckCircleOutlineIcon
                style={{ fontSize: "150px", color: "green" }}
              />
              <h3 className="my-4">Awesome!</h3>

              <LinkButton link="/profile" label="View Profile" />
              <LinkButton type="link" link="/home" label="Visit Home" />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withRouter(UserDetailsEdit);
