export const imagesGallery = [
  {
    src: "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q8_1000x1000.jpg?alt=media&token=e1aa030e-de7d-4b8d-bfa8-5e165de9458e",
    original:
      "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q8_1000x1000.jpg?alt=media&token=e1aa030e-de7d-4b8d-bfa8-5e165de9458e",
    // width: 400,
    // height: 400,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q7_1000x1000.jpg?alt=media&token=a3fed62a-0062-4a32-af93-1dc16acc2bae",
    original:
      "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q7_1000x1000.jpg?alt=media&token=a3fed62a-0062-4a32-af93-1dc16acc2bae",
    // width: 400,
    // height: 400,
  },

  {
    src: "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q6_1000x1000.jpg?alt=media&token=d33c1d7d-e425-41e5-a2fb-03d6500340c4",
    original:
      "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q6_1000x1000.jpg?alt=media&token=d33c1d7d-e425-41e5-a2fb-03d6500340c4",
    // width: 400,
    // height: 400,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q5_1000x1000.jpg?alt=media&token=af0a80c2-fe22-4373-8c6b-c504b55a7d8b",
    original:
      "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q5_1000x1000.jpg?alt=media&token=af0a80c2-fe22-4373-8c6b-c504b55a7d8b",
    // width: 400,
    // height: 400,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q4_1000x1000.jpg?alt=media&token=7d92d19e-b897-4b1f-9e29-042dfbeac61c",
    original:
      "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q4_1000x1000.jpg?alt=media&token=7d92d19e-b897-4b1f-9e29-042dfbeac61c",
    // width: 400,
    // height: 400,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q3_1000x1000.jpg?alt=media&token=5cf1d26c-e7fe-4ffe-9d5f-a3ba8cb19e74",
    original:
      "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q3_1000x1000.jpg?alt=media&token=5cf1d26c-e7fe-4ffe-9d5f-a3ba8cb19e74",
    // width: 400,
    // height: 400,
  },

  {
    src: "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q2_1000x1000.jpg?alt=media&token=e63e2c11-5841-4683-a7a0-1218cbd6707f",
    original:
      "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q2_1000x1000.jpg?alt=media&token=e63e2c11-5841-4683-a7a0-1218cbd6707f",
    // width: 400,
    // height: 400,
  },
  {
    //start
    src: "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q1_1000x1000.jpg?alt=media&token=c69a03a0-5f9b-4ceb-952d-f9f59b88abcd",
    original:
      "https://firebasestorage.googleapis.com/v0/b/thankynote.appspot.com/o/quotes%2Fthumbs%2FThankyNote-Q1_1000x1000.jpg?alt=media&token=c69a03a0-5f9b-4ceb-952d-f9f59b88abcd",
    // width: 400,
    // height: 400,
  },
];
