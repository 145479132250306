import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form";
import fire from "../../../services/fire";
import { Link } from "react-router-dom";
import SystemLogo from "../../blocks/systemLogoBlock";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import LoadingSpinner from "../../common/loadingSpinner";
import { logEvent } from "../../../services/log";
import { getLatestVersion } from "../../../services/getversion";
// import ReloadBar from "../../common/reloadBar";
import ReloadModal from "../../common/reloadModal";

class ForgotPassword extends Form {
  state = {
    data: { email: "" },
    errors: {},
    emailsent: false,
    loading: false,
    noemail: false,
    messageid: this.props?.match?.params?.messageid || "",
    version: null,
  };

  schema = {
    email: Joi.string().email().required().label("Email"),
  };

  goBack = () => {
    this.setState({ emailsent: false });
  };

  doSubmit = async () => {
    const { email } = this.state.data;
    this.setState({ loading: true });

    let auth = fire.auth();
    try {
      // Call the server

      await auth.sendPasswordResetEmail(email);
      logEvent("send_password_reset_success", {
        email: email,
      });
      this.setState({ loading: false, noemail: false, emailsent: true });
    } catch (error) {
      logEvent("send_password_reset_fail", {
        email: email,
        message: error.message,
      });
      this.setState({ noemail: true, loading: false, emailsent: true });
    }
  };

  async componentDidMount() {
    let el = document.querySelector("title");
    const title = `Forgot Password | ThankyNote`;
    const content = `Join now and together we spread gratitude around the world!`;
    el.innerText = title;
    el = document.querySelector("meta[name='description']");
    el.setAttribute("content", content);
    window.scrollTo(0, 0);
    const version = await getLatestVersion();
    this.setState({ version });
  }

  render() {
    const { version, data, emailsent, loading, noemail, messageid } =
      this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }

    return (
      <React.Fragment>
        {version && <ReloadModal version={version} />}
        <div className="my-4 pt-0"></div>
        <SystemLogo messageid={messageid} />
        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-4">
            <main className="container">
              {emailsent === false && (
                <React.Fragment>
                  <div className="pb-5 pt-4">
                    <h4 className="pb-2">Forgot your password?</h4>
                    <form onSubmit={this.handleSubmit}>
                      {this.renderInput("email", "Email", "text", "Your email")}
                      {!loading && this.renderBlockButton("Submit")}
                      {loading &&
                        this.renderLoadingBlockButton("Submitting...")}
                    </form>
                  </div>

                  <p className="normalsmallfontsize text-center">
                    <Link to={`/welcome`}> Return to Home</Link>
                  </p>
                  {/* <p className="normalsmallfontsize text-center">
                    <Link to={`/login/${messageid}`}> Return to log in</Link>
                  </p> */}
                </React.Fragment>
              )}
              {emailsent === true && noemail === false && (
                <React.Fragment>
                  <div className="pb-2 pt-5 text-center">
                    <EmailOutlinedIcon
                      style={{ fontSize: "80px", color: "#1b73e8" }}
                    />
                    <h1 className="pb-2">Email sent</h1>

                    <h5 className="py-2">
                      Please check your email to reset your password. You should
                      be receiving it shortly.
                    </h5>
                  </div>
                  <div className="pt-2 pb-4 text-left">
                    <p className="">
                      If you did not receive the email,{" "}
                      <b>check your spam folder after few minutes</b>.
                    </p>
                  </div>
                  <p className="normalsmallfontsize text-center">
                    <Link to={`/login/${messageid}`}> Return to log in</Link>
                  </p>
                </React.Fragment>
              )}
              {emailsent === true && noemail === true && (
                <React.Fragment>
                  <div className="pb-2 pt-5 text-center">
                    <h5 className="pt-2 pb-0">{data.email} not found.</h5>
                  </div>
                  <button
                    type="button"
                    onClick={() => this.goBack()}
                    className="btn btn-primary mt-4 btn-block btn-lg">
                    Try again
                  </button>
                  <p className="pt-4 mt-4 normalsmallfontsize text-center text-muted">
                    <Link to={`/register/${messageid}`}>Return to log in</Link>
                  </p>
                </React.Fragment>
              )}
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
