import React, { Component } from "react";
import Header from "../../common/header";
import { Link } from "react-router-dom";
import { getCurrentUser, getCurrentUserData } from "../../../services/user";
import { getMassRegistrationLink } from "../../../utils/utils";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import MainMenuCardSimplePanel from "../../blocks/mainMenuCardPanelSimpleBlock";
import { getParishLinkById } from "../../../services/parish";
import SimpleMessage from "../../blocks/simpleMessageBlock";
import ScrollToTop from "../../common/scrollToTop";
import fire from "../../../services/fire";
import UserEntryTable from "../component/userEntryTable";
import { Button, Modal } from "react-bootstrap";
import { isEmpty } from "../../../utils/objs";
import Input from "../../common/input";
import SpinnerText from "../../common/spinnerwithText";
import ResizeTextarea from "../../common/resizeTextarea";

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menutype: "light",

      data: {
        fullname: "",
        email: "",
        mobile: "",
        dob: "",
        identification: "",
      },
      userlist: [],
      user: this.props.user,
      loading: true,
      showmodal: false,
      selected: null,
      sending: false,
      templateId: "d-4ba5a23a8e0c4917838d6d01067c82eb",
      sender: "ThankyNote",
      subject: "The Power of Gratitude: How It Can Transform Your Life 🌈",
      header: "",
      emailbody: "",
      emailps: "",
      errors: {},
    };
  }
  compare(a, b) {
    if (a.totalnotes < b.totalnotes) {
      return 1;
    }
    if (a.totalnotes > b.totalnotes) {
      return -1;
    }
    return 0;
  }

  async getUserList() {
    try {
      const db = fire.firestore();

      const users = await db.collection("users").get();
      let list = [];
      users.forEach((doc) => {
        list.push(doc.data());
      });
      console.log(list.filter((i) => !i?.test));

      this.setState({
        userlist: list.filter((i) => !i?.test).sort(this.compare),
        loading: false,
      });
    } catch (error) {
      console.error("Users not yet created");
      this.setState({ loading: false });
    }
  }

  getCurrentUserData = async () => {
    try {
      const uid = this.state.user.uid;
      const user = await getCurrentUserData(uid);

      if (user) {
        const { fullname, email, mobile, dob, identification } = user;
        this.setState({
          data: {
            fullname,
            email,
            mobile,
            dob,
            identification,
          },
        });
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log("Error:", error);
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    //check if allowed
    const user = await getCurrentUser();
    if (!user) {
      this.props.history.push("/welcome");
      return;
    }
    if (
      user.uid === "mTdWaLm65nSWzXU1cHE4PmVmUPm1" ||
      user.uid === "VYj16iAHJJh5WVdimE5BfTvluN63"
    ) {
      await this.getCurrentUserData();
      await this.getUserList();
    } else {
      this.props.history.push("/home");
      return;
    }
  }

  getAnnouncementLink(item) {
    const label = item.header;
    const link = `/announcement/view/${item.id}`;
    return <Link to={link}>{label}</Link>;
  }

  getMainMenuPanels(parish) {
    let parishlink = `parish/${getParishLinkById(parish)}`;
    let menutype = this.state.menutype;
    return (
      <div className="row justify-content-center">
        <div className="container">
          <MainMenuCardSimplePanel
            menulink="/admin/announcement"
            menulinklabel="Announcements"
            menuicon="announcement"
            menutype={menutype}
          />
          <MainMenuCardSimplePanel
            menulink={parishlink}
            menulinklabel="Parishioners List"
            menuicon="parishioners"
            menutype={menutype}
          />
          <MainMenuCardSimplePanel
            menulink={getMassRegistrationLink(parish)}
            menulinklabel="Mass Registrations"
            menuicon="massregistrations"
            badgelabel="Coming soon"
            menutype={menutype}
          />
          <MainMenuCardSimplePanel
            menulink="feedback"
            menulinklabel="Feedback Manager"
            menuicon="feedback"
            menutype={menutype}
          />
        </div>
      </div>
    );
  }

  doClick = async (item) => {
    console.log("doClick");
    console.log({ item });

    //send email here
    this.setState({ showmodal: true, selected: item });
  };

  doSend = async () => {
    console.log("doSend");
    const {
      selected,
      sender,
      header,
      emailbody,
      emailps,
      subject,
      templateId,
    } = this.state;

    let errors = {};

    if (sender.trim() === "") {
      errors["sender"] = "Sender must not be empty";
    }
    if (templateId.trim() === "") {
      errors["templateId"] = "Template ID must not be empty";
    }
    if (header.trim() === "") {
      errors["header"] = "Header must not be empty";
    }
    if (subject.trim() === "") {
      errors["subject"] = "Subject must not be empty";
    }
    if (emailbody.trim() === "") {
      errors["emailbody"] = "Body must not be empty";
    }

    console.log(errors);
    if (!isEmpty(errors)) {
      this.setState({ errors: errors });
      return;
    }

    console.log(selected);
    this.setState({ sending: true });
    //do await stuff here
    const fullname = selected?.fullname?.trim()?.toLowerCase();
    //fullname may contain many words, convert first letter to uppercase of each word
    const capsName = fullname
      ?.split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    //check contents of emailbody, if there's a new line, then replace with <br/>, if there's 2 new lines, then replace with <br/><br/>
    const emailbodylines = emailbody?.split("\n");
    let emailbodylineswithp = "";
    emailbodylines.forEach((line) => {
      if (line.trim() === "") {
        emailbodylineswithp = `${emailbodylineswithp}<br/><br/>`;
      } else {
        emailbodylineswithp = `${emailbodylineswithp}${line}<br/>`;
      }
    });
    let publiclink = "https://thankynote.com/inspirations";
    if (templateId?.trim() === "d-464063ec1d8a48ea9f5047951c3914fa") {
      publiclink = `https://thankynote.com/2023/${selected?.userid}`;
      let count = 0;
      if (selected["totalnotes"] > 0) {
        if (selected["2023"] > 0) {
          count = selected["2023"];
        } else {
          //use total notes
          count = selected["totalnotes"];
        }
        emailbodylineswithp = `${count}`;
      } else {
        //empty total notes
        emailbodylineswithp = ``;
      }
    }
    const param = {
      templateId: templateId?.trim(),
      email: selected?.email?.trim(),
      name: capsName?.trim(),
      sender: sender?.trim() || "Jeyner from ThankyNote",
      receiver: capsName?.trim(),
      subject:
        subject?.trim() ||
        "The Power of Gratitude: How It Can Transform Your Life 🌈",
      publiclink: publiclink,
      header: header || "",
      emailbody: emailbodylineswithp || "",
      emailps: emailps || "",
      emailType: "dynamic",
      receiverimagelink: "",
      senderimagelink: "",
    };
    console.log(param);
    await this.handleSendEmail(param);
    this.setState({ sending: false, showmodal: false, selected: null });
  };

  handleSendEmail = async (param) => {
    // console.log({ param });
    const genericThankYouNote = fire
      .functions("asia-east2")
      .httpsCallable("genericThankYouNote");
    try {
      console.log("Calling genericThankYouNote...");
      const result = await genericThankYouNote(param);
      if (result.data.status === 0) {
        console.log("Success...");
      } else {
        console.log("Fail...");
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  getUserPanel = () => {
    const { userlist } = this.state;
    return (
      <React.Fragment>
        <div id="users" className="my-4 pt-0">
          <UserEntryTable
            item={userlist}
            handleClick={(i) => this.doClick(i)}
          />
          {userlist.length === 0 && (
            <SimpleMessage
              title="Stay safe"
              content="No one has registered at the moment."
              link="/ctrl"
              label="Back to Dashboard"
              placement="center"
            />
          )}
        </div>

        {userlist.length > 1 && (
          <React.Fragment>
            <ScrollToTop />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  handleChange = ({ currentTarget: input }) => {
    const { name, value } = input;

    this.setState({ [name]: value });
  };

  render() {
    const {
      loading,
      userlist,
      sender,
      header,
      emailbody,
      emailps,
      sending,
      subject,
      templateId,
      errors,
    } = this.state;

    if (loading === true) {
      return <LoadingSpinnerNav type="backandlogo" />;
    }
    console.log(errors);
    return (
      <div className="p-2">
        <Header type="backandlogo" />
        <h1 className="pt-4 pb-2 mb-0 text-wrap">
          Top Users ({userlist.length})
        </h1>
        {this.getUserPanel()}
        <Modal
          size="lg"
          show={this.state.showmodal}
          onHide={() => this.setState({ showmodal: false, selected: null })}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Header style={{ border: "none" }}>
            <h4>
              Send Email to{" "}
              <span className="text-danger">{this.state.selected?.email}</span>
            </h4>
          </Modal.Header>
          <Modal.Body className="mt-0 mb-4 text-left appearslowly">
            <Input
              type="text"
              name="sender"
              value={sender}
              label="Sender Name"
              placeholder="ex. Jeyner from ThankyNote"
              onChange={this.handleChange}
              error={errors["sender"]}
            />
            <Input
              type="text"
              name="subject"
              value={subject}
              label="Subject"
              placeholder="ex. The Power of Gratitude: How It Can Transform Your Life 🌈"
              onChange={this.handleChange}
              error={errors["subject"]}
            />
            <Input
              type="text"
              name="header"
              value={header}
              label="Header"
              placeholder="ex. Unlock a happier you with ThankyNote!"
              onChange={this.handleChange}
              error={errors["header"]}
            />
            <Input
              type="text"
              name="templateId"
              value={templateId}
              label="Template ID"
              placeholder="ex. d-08f99495357c45c4bbf3128d949da7c9"
              onChange={this.handleChange}
              error={errors["templateId"]}
            />
            <ResizeTextarea
              autoFocus={false}
              onKeyDown={(e) => {}}
              type="text"
              name="emailbody"
              value={emailbody}
              label="Email Body (HTML/Text)"
              placeholder="Type your email body here..."
              onChange={this.handleChange}
              error={errors["emailbody"]}
            />
            <Input
              type="text"
              name="emailps"
              value={emailps}
              label="P.S. (HTML/Text)"
              placeholder="ex. PS: You can also send a ThankyNote to your friends and family."
              onChange={this.handleChange}
              error={errors["emailps"]}
            />
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button
              variant="link"
              className="forcenoline noline mr-2"
              onClick={() =>
                this.setState({ showmodal: false, selected: null })
              }>
              <span className="text-muted forcenoline noline">Close</span>
            </Button>
            <Button
              variant="success"
              disabled={sending}
              className=" mr-2"
              onClick={() => this.doSend()}>
              {sending ? (
                <SpinnerText text="Sending..." />
              ) : (
                <span className=" ">Send</span>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default UserList;
