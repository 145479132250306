import React from "react";
import { getWebVersion } from "../../services/settings";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="clearfix"></div>
      <div className="col-12 pt-0 mt-0 mb-0 text-center">
        <p className="pt-0 mt-0 mb-0 text-muted">
          &copy; {new Date().getFullYear()} ThankyNote Beta v{getWebVersion()}
          <br /> All Rights Reserved.
        </p>
        <p className="text-muted">
          Made with{" "}
          <span role="img" aria-label="with love">
            ❤️
          </span>{" "}
          by{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/jeynergilcaga">
            Jeyner
          </a>
        </p>
        <div className="d-flex justify-content-center">
          <p className="text-muted px-1">
            <a rel="noopener noreferrer" href="https://thankynote.com/quotes">
              Quotes
            </a>
          </p>
          &bull;
          <p className="text-muted px-1">
            <a
              rel="noopener noreferrer"
              href="https://thankynote.com/inspirations">
              Samples
            </a>
          </p>
          &bull;
          <p className="text-muted px-1">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.thankynote.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              Android
            </a>
          </p>
          &bull;
          <p className="text-muted px-1">
            <a rel="noopener noreferrer" href="https://thankynote.com/privacy">
              Privacy
            </a>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
