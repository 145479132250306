/*
 * These config settings can be placed in separate config page
 */
export function getAllowedBookingCurrentDates() {
  return ["oct2020", "aug2020", "sep2020", "jul2020"];
}

export function getAllowedCurrentDates() {
  return ["sep2020", "oct2020"];
}

export function getJulyMonth() {
  return "jul";
}

export function getAugustMonth() {
  return "aug";
}

export function getPreviousMonth() {
  return "sep";
}

export function getNextMonth() {
  return "oct";
}

export function getJulyBookingLabel() {
  return "July Booking";
}

export function getAugustBookingLabel() {
  return "August Booking";
}

export function getPreviousMonthBookingLabel() {
  return "September Booking";
}

export function getNextMonthBookingLabel() {
  return "October Booking";
}

export function getNextMonthMasstoUpload() {
  return "oct2020";
}

export function getNextMonthDate() {
  return "October 1, 2020";
}

export function getNextFullMonth() {
  let nextmonth = getNextMonthDate().split(" ");
  return nextmonth[0];
}

export function getCutOffTime() {
  //48 hours
  return 48;
}

export function enableCancelMassBooking() {
  return true;
}

export function enableEditBasicProfile() {
  return true;
}

export function enableFirstBookingReminderAdmin() {
  return false;
}

export function disableBooking() {
  return false;
}

export function enableProfilePhotoUpload() {
  return true;
}

export function enableVerifyEmail() {
  return false;
}

export function enableWebVersion() {
  return false;
}

export function enableThankYouOthers() {
  return false;
}

export function enableSaveThankyNote() {
  return false;
}

export function isGod(uid) {
  if (uid === "eZTw1KEQEMQ5mLEiupUeonn8vw23") {
    return true;
  }
  return false;
}

export function isThankyNote(uid) {
  if (uid === "TPeFf2XtcZW4p70RGZvTZoXRLfB3") {
    return true;
  }
  return false;
}

export function isThankyNoteCorpUser(user) {
  const uid = user.uid;
  const test = user?.test === true;
  if (test) {
    return true;
  }
  if (uid === "eZTw1KEQEMQ5mLEiupUeonn8vw23") {
    //God
    return true;
  } else if (uid === "VYj16iAHJJh5WVdimE5BfTvluN63") {
    //Gem
    return true;
  } else if (uid === "mTdWaLm65nSWzXU1cHE4PmVmUPm1") {
    //Me
    return true;
  } else if (uid === "TPeFf2XtcZW4p70RGZvTZoXRLfB3") {
    //ThankyNote
    return true;
  }
  return false;
}

export function getWebVersion() {
  return "0.8.81"; //To force all users to reload
}
