import React from "react";

function DownloadApp({ type = "default", css = "pt-2 pb-1", size = 100 }) {
  let design = (
    <a
      className="forcenoline text-center"
      target="_blank"
      rel="noopener noreferrer"
      href="https://play.google.com/store/apps/details?id=com.thankynote.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
      <div
        className={`pointer alert alert-white shadow-sm px-3 ${css} mb-0 text-center appearslowly`}>
        <p className="mb-0 standardfontsize">
          ThankyNote is now available on Google Play! 🎉🚀
        </p>
        <img
          alt="Get it on Google Play"
          width={size}
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        />
        <div className="forcenoline standardfontsize text-muted pt-0">
          Coming soon on iOS
        </div>
      </div>
    </a>
  );
  if (type === "simple") {
    design = (
      <a
        className="forcenoline text-center"
        target="_blank"
        rel="noopener noreferrer"
        href="https://play.google.com/store/apps/details?id=com.thankynote.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <div className="pointer px-3 pt-2 pb-1 mb-0 text-center appearslowly">
          <p className="mb-0 standardfontsize">
            ThankyNote is now available on Google Play! 🎉🚀
          </p>
          <img
            alt="Get it on Google Play"
            width={size}
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
          <div className="forcenoline text-muted pt-2 standardfontsize">
            Coming soon on iOS
          </div>
        </div>
      </a>
    );
  } else if (type === "download") {
    design = (
      <a
        className="forcenoline text-center"
        target="_blank"
        rel="noopener noreferrer"
        href="https://play.google.com/store/apps/details?id=com.thankynote.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <div
          className={`alert alert-success roundcornernocolor pointer px-3 ${css} mb-0 text-center appearslowly`}>
          <p className="mb-0 standardfontsize font-weight-bold">
            Get it on Google Play!
          </p>
          <img
            alt="Get it on Google Play"
            width={size}
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </div>
        <div className="forcenoline text-muted pt-2 standardfontsize">
          Coming soon on iOS
        </div>
      </a>
    );
  }

  return design;
}

export default DownloadApp;
