import React from "react";
import { formatTime } from "../../../utils/utils";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import { Image, Media } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import { convertFireStorageUrl } from "../../../utils/url";
import { isGod } from "../../../services/settings";

const ViewContent = ({ ...rest }) => {
  const { item, design, type } = rest;
  // console.log(item);
  let photo;
  let text;
  let timestamp;
  let action;
  let flag;
  if (type === "notes") {
    text = `${item.author}`;
    photo = item.smallpic;
    timestamp = item.created;
    action = "Posted on";
    flag = item.country;
  } else if (type === "sent") {
    text = `Sent to ${item.recepientname}`;
    photo = item.recepientpic;
    timestamp = item.received;
    action = "Saved on";
    flag = item.recepientcountry;
  } else if (type === "received") {
    text = `From ${item.author}`;
    photo = item.smallpic;
    timestamp = item.received;
    action = "Saved on";
    flag = item.country;
  } else if (type === "sender") {
    text = `${item.fullname}`;
    photo = item.bigpic;
    timestamp = item.created;
    action = "Joined ";
    flag = item.country;
  } else if (type === "receiver") {
    text = `${item.fullname}`;
    photo = item.bigpic;
    timestamp = item.created;
    action = "Joined ";
    flag = item.country;
  } else {
    text = `Message from ${item.author}`;
    photo = item.smallpic;
    timestamp = item.created;
    action = "Sent ";
    flag = item.country;
  }
  const isgod = isGod(item?.userid);
  let timeago = Date.now() - timestamp;

  if (timeago < 7 * 24 * 60 * 60 * 1000) {
    timeago = formatTime(timestamp);
    timeago = <span className="">&bull; {timeago}</span>;
  } else {
    timeago = null;
  }

  let output;

  if (design === 0) {
    output = (
      <>
        <div className="text-center pt-0">
          {!photo && (
            <AccountCircleTwoToneIcon
              className="text-primary"
              style={{ fontSize: "50px" }}
            />
          )}
          {photo && (
            <Image
              src={convertFireStorageUrl(photo)}
              width="60px"
              height="60px"
              alt=""
              roundedCircle
            />
          )}
          <h5 className="text-center mt-2 mb-0 pb-0">{text}</h5>
          {!isgod && (
            <div className="text-center pt-0">
              <small>
                {action} {new Date(timestamp).toDateString()} {timeago}
                {flag && (
                  <span className="">
                    <ReactCountryFlag
                      countryCode={flag}
                      className="emojiFlag ml-1"
                      style={{
                        fontSize: "1em",
                        lineHeight: "1em",
                      }}
                    />
                  </span>
                )}
              </small>
            </div>
          )}
        </div>
      </>
    );
  } else if (design === 1) {
    return (
      <div
        className="pt-0 mt-4 pb-3 pl-4"
        style={{
          // borderTop: "#e0e0e0 1px dashed",
          borderBottom: "#efefef 1px dashed",
        }}>
        <Media className="mb-2">
          <div className="text-center">
            {!photo && (
              <AccountCircleOutlinedIcon
                className="text-primary"
                style={{ fontSize: "50px" }}
              />
            )}
            {photo && (
              <Image
                src={convertFireStorageUrl(photo)}
                width="40px"
                height="40px"
                alt=""
                className="mr-2"
                roundedCircle
              />
            )}
          </div>
          <Media.Body>
            <h5 className="text-left mt-0 mb-0 pb-0">{item.author}</h5>
            <div className="text-left pt-0 text-muted">
              <small>
                Sent {new Date(item.created).toDateString()} {timeago}
              </small>
            </div>
          </Media.Body>
        </Media>
      </div>
    );
  }
  return output;
};

export default ViewContent;
