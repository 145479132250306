import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import fire from "../../../services/fire";
import firebase from "firebase/app";
import SpinnerText from "../../common/spinnerwithText";
import FormatQuoteOutlinedIcon from "@material-ui/icons/FormatQuoteOutlined";
import ViewContent from "./viewContent";
import ViewContentBody from "./viewContentBody";
import ResizeTextarea from "../../common/resizeTextarea";
import ProfileTime from "./profileTime";
import { addNewRelationship } from "../../friends/services/friends";
import { sendNotifications } from "../../../services/notifications";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import { getCurrentUserData } from "../../../services/user";

class ThankyouContentPanelPublic extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props.item);
    this.state = {
      user: this.props.user,
      country: this.props.country,
      loggedin: this.props.user ? true : false,
      messageid: this.props.messageid || "",
      suggestlogin: false,
      typingdone: false,
      // selectedentry: this.props.item,
      replycomplete: false,
      reply: this.props.item.reply || "🙏",
    };
  }

  footer = (user) => {
    return (
      <div className="pb-4 my-4 text-muted text-center">
        {user && (
          <div className="pt-4 pb-4">
            <Link to="/welcome">Go to Home</Link>
          </div>
        )}
        {/* <div>
          Write your own{" "}
          <Link to="/welcome">
            <PostAddOutlinedIcon style={{ marginTop: "-5px" }} />
            ThankyNote
          </Link>
        </div> */}
      </div>
    );
  };

  handleSendEmail = async (param) => {
    // console.log({ param });
    const genericThankYouNote = fire
      .functions("asia-east2")
      .httpsCallable("genericThankYouNote");
    try {
      console.log("Calling genericThankYouNote...");
      const result = await genericThankYouNote(param);
      if (result.data.status === 0) {
        console.log("Success...");
      } else {
        console.log("Fail...");
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  handleGenerateReplyasGod = async (user, item) => {
    this.setState({ saving: true });
    //check if user is God or ThankyNote
    const replyAsGod = fire.functions("asia-east2").httpsCallable("replyAsGod");
    try {
      console.log("Calling replyAsGod...");
      const result = await replyAsGod({
        content: item?.content || "Thank you Lord for everything!",
      });
      if (result.data.status === 0) {
        console.log("Success...");
        console.log(`Reply: ${result.data.message}`);
        this.setState({ reply: result.data.message, saving: false });
      } else {
        console.log("Fail...");
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  handleGenerateReplyasThankyNote = async (user, item) => {
    this.setState({ saving: true });
    //check if user is God or ThankyNote
    const replyAsThankyNote = fire
      .functions("asia-east2")
      .httpsCallable("replyAsThankyNote");
    try {
      console.log("Calling replyAsThankyNote...");
      const result = await replyAsThankyNote({
        content: item?.content || "I am grateful for everything!",
      });
      if (result.data.status === 0) {
        console.log("Success...");
        console.log(`Reply: ${result.data.message}`);
        this.setState({ reply: result.data.message, saving: false });
      } else {
        console.log("Fail...");
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  handleSave = async (user, item) => {
    // console.log({ item });
    const curtime = new Date().getTime();
    this.setState({ saving: true });
    try {
      const db = fire.firestore();

      const notes = await db
        .collection("notes")
        .where("id", "==", item.id)
        .get();
      if (notes) {
        let id = null;
        const list = [];
        notes.forEach((doc) => {
          id = doc.id;
          list.push(doc.data());
        });

        const notesref = fire.firestore().collection("notes").doc(id);
        //update displayName and photoURL
        const userObj = await getCurrentUserData(user.uid);
        const { fullname, smallpic } = userObj;
        await user.updateProfile({
          displayName: fullname,
          photoURL: smallpic || "",
        });
        const docData = {
          recepientid: user.uid,
          hasrecepient: true, //we use this as a filter to simplify our search those who have recepientid
          recepientname: fullname || user.displayName,
          received: curtime,
          recepientpic: smallpic || user.photoURL || "",
          recepientcountry: this.state.country || "",
        };
        await notesref.update(docData);

        //update received counter
        const receiver = db.collection("users").doc(user.uid);
        await receiver.update({
          totalreceived: firebase.firestore.FieldValue.increment(1),
        });
        //update sent counter
        const sender = db.collection("users").doc(item.authorid);
        await sender.update({
          totalsent: firebase.firestore.FieldValue.increment(1),
        });

        let recvmail;
        if (item.email) {
          recvmail = item.email;
        } else {
          //no email, get email
          const recvobj = await db.doc(`users/${item.authorid}`).get();

          if (recvobj && recvobj.exists) {
            const userObj = recvobj.data();
            recvmail = userObj.email;
          }
        }
        const sendername = item.author;
        const param = {
          email: recvmail,
          name: sendername.trim(),
          sender: sendername,
          receiver: user.displayName,
          subject: `${sendername} saved your ThankyNote 👍`,
          publiclink: `https://thankynote.com/p/${item.id}`,
          emailType: "notesaved",
          receiverimagelink: "",
          senderimagelink: "",
        };
        await this.handleSendEmail(param);
        //try to add new relationship
        const senderObj = {
          id: item?.authorid,
          name: item?.author,
          photo: item?.smallpic || "",
          country: item?.country || "",
        };
        const recvObj = {
          id: user.uid,
          name: user.displayName,
          photo: user.photoURL || "",
          country: this.state.country || "",
        };
        await addNewRelationship(senderObj, recvObj);
        const from = {
          id: user.uid,
          name: user.displayName,
          photo: user.photoURL || "",
        };
        const to = {
          id: item.authorid,
          name: item.author,
          photo: item.smallpic || "",
        };
        await sendNotifications("notesaved", from, to, item);
        console.log("Sending notesaved");
        this.setState({ saved: true, saving: false });
      } else {
        this.setState({ error: true, saving: false });
      }
    } catch (error) {
      console.error("Error writing document: ", error);
      this.setState({ error: true, saving: false });
    }
  };

  handleRedirect = (user, item) => {
    // const messageid = this.state.messageid;
    // const link = `/welcome/${messageid}`;
    // this.props.history.push(link);
    window.scrollTo(0, 0);
    this.setState({ suggestlogin: true });
  };

  handleTypingDone = () => {
    // console.log("Done");
    this.setState({ typingdone: true });
  };

  handleChange = ({ currentTarget: input }) => {
    this.setState({ reply: input.value });
  };

  handleReply = async (item) => {
    const { reply } = this.state;
    this.setState({ replying: true });

    if (reply.trim().length === 0) {
      this.setState({ reply: "", replying: false });
      return;
    }
    // console.log({ selectedentry });
    // console.log({ reply });
    const curtime = new Date().getTime();
    // const newentry = { ...selectedentry, reply };
    const db = fire.firestore();
    let userpic = "";
    const notes = await db.collection("notes").where("id", "==", item.id).get();
    if (notes) {
      let id = null;
      const list = [];
      notes.forEach((doc) => {
        id = doc.id;
        list.push(doc.data());
      });

      const notesref = fire.firestore().collection("notes").doc(id);
      const loggedin = this.state.loggedin;

      userpic = this.props.user?.photoURL || "";
      const hasrecepient = list[0].hasrecepient || false;
      // console.log({ hasrecepient });
      let extra = {};
      let receivername = "Somebody";
      if (loggedin) {
        const user = this.props.user;
        receivername = user.displayName;
        if (!hasrecepient) {
          extra = {
            recepientid: user.uid,
            hasrecepient: true, //we use this as a filter to simplify our search those who have recepientid
            recepientname: user.displayName,
            received: curtime,
            recepientpic: user.photoURL,
            recepientcountry: this.state.country || "",
            replyuid: user.uid,
            replyusername: user.displayName,
            replycountry: this.state.country || "",
          };
        }
      }
      const docData = {
        ...extra,
        replyphoto: userpic,
        reply: reply.trim(),
        replytime: curtime,
      };
      await notesref.update(docData);
      if (loggedin) {
        if (!hasrecepient) {
          const user = this.props.user;

          //update received counter
          const receiver = db.collection("users").doc(user.uid);
          await receiver.update({
            totalreceived: firebase.firestore.FieldValue.increment(1),
          });
          //update sent counter
          const sender = db.collection("users").doc(item.authorid);
          await sender.update({
            totalsent: firebase.firestore.FieldValue.increment(1),
          });
        }
        let param;
        let recvmail;
        if (item.email) {
          recvmail = item.email;
        } else {
          //no email, get email from user obj
          const recvobj = await db.doc(`users/${item.authorid}`).get();

          if (recvobj && recvobj.exists) {
            const userObj = recvobj.data();
            recvmail = userObj.email;
          }
        }
        param = {
          email: recvmail,
          name: item.author,
          sender: item.author,
          receiver: receivername,
          subject: `👏 ${receivername} replied to your ThankyNote`,
          publiclink: `https://thankynote.com/p/${item.id}`,
          emailType: "notereply",
          receiverimagelink: "",
          senderimagelink: "",
        };
        await this.handleSendEmail(param);

        //try to add new relationship
        const senderObj = {
          id: item?.authorid,
          name: item?.author,
          photo: item?.smallpic || "",
          country: item?.country || "",
        };
        const user = this.props.user;

        const recvObj = {
          id: user.uid,
          name: user.displayName,
          photo: user.photoURL || "",
          country: this.state.country || "",
        };
        // console.log({ senderObj });
        // console.log({ recvObj });
        await addNewRelationship(senderObj, recvObj);

        const sender = {
          id: user.uid,
          name: user.displayName,
          photo: user.photoURL || "",
        };
        const receiver = {
          id: item.authorid,
          name: item.author,
          photo: item.smallpic || "",
        };
        await sendNotifications("notereply", sender, receiver, item);
        this.setState({ autosave: true, userpic });
      } else {
        //not logged in, notify sender "Somebody replied to your ThankyNote :)"
        //add to notifications tab
        const sender = null;
        const receiver = {
          id: item.authorid,
          name: item.author,
          photo: item.smallpic || "",
        };
        await sendNotifications("notereply", sender, receiver, item);
      }
    }
    //process here
    this.setState({
      replying: false,
      replycomplete: true,
      userpic,
      // selectedentry: newentry,
    });
  };

  render() {
    const { item, user } = this.props;
    const saved = this.state.saved;
    const error = this.state.error;
    const saving = this.state.saving;
    const reply = this.state.reply;
    const recepientid = item.recepientid;
    const autosave = this.state.autosave;
    // const typingdone = this.state.typingdone;
    const replycomplete = this.state.replycomplete;
    const haspic = item.replyphoto || user?.photoURL ? true : false;
    const userpic = item.replyphoto ? item.replyphoto : user?.photoURL;
    // console.log({ haspic });
    // console.log(item.replyphoto);
    // console.log(user?.photoURL);
    // // const selectedentry = this.state;
    // console.log(this.state);
    // const author = item.author;
    let hasreply = false; //entry.reply?.length > 0 ? true : false;
    const loggedin = this.props.user ? true : false;
    let allowsave = false;
    if (user) {
      if (user.uid !== item.authorid) {
        //if the user is not same as the author
        allowsave = true;
      }
    }
    let timeago = Date.now();
    if ((item.reply && item.reply.length > 0) || replycomplete) {
      hasreply = true;
      timeago = new Date().toDateString();
    }
    // console.log({ allowsave });
    // console.log({ recepientid });
    // console.log({ saved });
    // console.log({ loggedin });

    // if (timeago < 7 * 24 * 60 * 60 * 1000) {
    //   timeago = formatTime(timeago);
    //   timeago = <span className="">&bull; {timeago}</span>;
    // } else {
    //   timeago = null;
    // }
    if (this.state.suggestlogin) {
      const messageid = this.state.messageid;

      return (
        <div className=" text-center pb-4 appearslowly">
          <div className="">
            <span
              className=""
              style={{ fontSize: "50px" }}
              role="img"
              aria-label="think">
              👍
            </span>
          </div>
          <p className="defaultfontsize my-4 px-2 pt-0 pb-0">
            Wow! <b>{item.author}</b> sent you a ThankyNote{" "}
            <PostAddOutlinedIcon
              className="text-primary"
              style={{ marginTop: "-5px" }}
            />
            .
          </p>
          <p className="font-weight-bold defaultfontsize pt-4 my-4 px-2 pt-0 pb-0">
            To save note, log in to your account.
          </p>
          <div className="py-4 my-4">
            <Link
              className="btn-primary btn-lg px-4 my-4"
              to={`/login/${messageid}`}>
              Log in to account
            </Link>
          </div>
          <div className="my-4">
            <Link
              className="btn-success btn-lg my-4"
              to={`/register/${messageid}`}>
              Create new account
            </Link>
          </div>
          <div className="pt-2 my-4 px-0 text-muted">
            <hr />
            {/* <p className="pt-2 my-4">For people practising daily gratitude.</p> */}
            <div className="pb-4" style={{ height: "250px" }}>
              <FormatQuoteOutlinedIcon
                className="px-0 text-center text-primary"
                style={{ fontSize: "60px", transform: "rotate(180deg)" }}
              />
              <Carousel controls={false} fade>
                <Carousel.Item>
                  <div
                    className="d-block"
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}>
                    <p className="text-dark pt-2 pb-4">
                      "Be thankful for what you have, you'll end up having
                      more."
                      <br />
                      <br />
                      ~Oprah Winfrey
                    </p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div
                    className="d-block"
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}>
                    <p className="text-dark pt-2 pb-4">
                      "We must find time to stop and thank the people who make a
                      difference in our lives."
                      <br />
                      <br />
                      ~John F. Kennedy
                    </p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div
                    className="d-block"
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}>
                    <p className="text-dark pt-2 pb-4">
                      "Acknowledging the good that you already have in your life
                      is the foundation for all abundance."
                      <br />
                      <br />
                      ~Eckhart Tolle
                    </p>
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <div
                    className="d-block"
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}>
                    <p className="text-dark pt-2 pb-4">
                      "When you practice gratefulness, there is a sense of
                      respect towards others."
                      <br />
                      <br />
                      ~Dalai Lama
                    </p>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        <div
          className="card pt-0 mx-0"
          style={{ border: "1px dashed #d0d0d0" }}>
          <div className="pt-4">
            <ViewContent item={item} design={0} />
          </div>
          <div className="py-3">
            <ViewContentBody
              item={item}
              animate={true}
              onTypingDoneHandler={this.handleTypingDone}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ThankyouContentPanelPublic);
