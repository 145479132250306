import React, { Component } from "react";
import Header from "../../common/header";
import { getCurrentUserData, userLogout } from "../../../services/user";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import LinkButton from "../../blocks/linkButtonBlock";
import { getLatestVersion } from "../../../services/getversion";
// import ReloadBar from "../../common/reloadBar";
import {
  generateFriends,
  getGeneratedFriends,
  saveGeneratedFriends,
} from "../services/friends";
import ScrollToTop from "../../common/scrollToTop";
import FriendList from "../component/friendList";
import FriendInfoModal from "../component/friendInfoModal";
import NoFriends from "../component/noFriends";
import fire from "../../../services/fire";
import ReloadModal from "../../common/reloadModal";

class FriendsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        fullname: "",
        email: "",
        storecontact: "",
        storelink: "",
        storename: "",
        streak: [],
      },
      friends: [],
      user: this.props.user,
      loading: true,
    };
  }

  async getCurrentUserData() {
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const {
        totalnotes,
        totalreceived,
        totalsent,
        fullname,
        email,
        storecontact,
        storelink,
        storename,
        bigpic,
        country,
        streak,
        hasgeneratedfriends,
      } = user;
      const photoUrl = bigpic;
      let el = document.querySelector("title");
      const title = `Your Friends in ThankyNote`;
      const content = `Together we spread gratitude around the world!`;
      el.innerText = title;
      el = document.querySelector("meta[name='description']");
      el.setAttribute("content", content);
      const version = await getLatestVersion();

      let buildfriends = false;
      let friends = [];
      if (!hasgeneratedfriends) {
        //build here
        friends = await generateFriends(uid);
        //write friends to db
        friends = await saveGeneratedFriends(uid, friends);
        // console.log({ friends });
        buildfriends = true;
        //update flag
        const db = fire.firestore();

        const userRef = db.doc(`users/${uid}`);
        await userRef.update({ hasgeneratedfriends: true });
      } else {
        //get from collection here

        friends = await getGeneratedFriends(uid);
      }
      // console.log({ friends });
      // console.log(friends.length);

      //exclude friends with uid = eZTw1KEQEMQ5mLEiupUeonn8vw23 and uid = TPeFf2XtcZW4p70RGZvTZoXRLfB3
      friends = friends.filter((entry) => {
        if (
          entry.friendid === "eZTw1KEQEMQ5mLEiupUeonn8vw23" ||
          entry.friendid === "TPeFf2XtcZW4p70RGZvTZoXRLfB3"
        ) {
          return null;
        } else {
          return entry;
        }
      });
      // console.log({ friends });
      // console.log(friends.length);

      this.setState({
        version,
        totalnotes,
        totalreceived,
        totalsent,
        friends,
        hasgeneratedfriends: buildfriends,
        data: {
          fullname,
          email,
          storecontact,
          storelink,
          storename,
          country,
          streak,
        },
        photoUrl,
        loading: false,
      });
    } else {
      await userLogout();
    }
  }

  componentDidMount() {
    this.getCurrentUserData();
  }

  reloadProfile = () => {
    window.location.reload();
  };

  processUserView = async (entry) => {
    // console.log(entry);
    // console.log(type);
    const id = entry.friendid;
    //get recepient details
    // const id = type === "sent"?entry.recepientid;
    const user = await getCurrentUserData(id);
    // console.log(user);
    this.setState({
      viewusermodal: { showmodal: true, obj: user },
    });
  };

  handleClose = () => {
    this.setState({
      viewusermodal: { showmodal: false, obj: null },
    });
  };

  viewUserModal = () => {
    const viewusermodal = this.state.viewusermodal;
    if (!viewusermodal && !viewusermodal?.obj) {
      return null;
    }
    return (
      <FriendInfoModal
        args={viewusermodal}
        // showmodal={showmodal}
        closeHandler={this.handleClose}
        // obj={obj}
        // type={type}
      />
    );
  };

  render() {
    const { loading, version, friends } = this.state;

    if (loading === true) {
      return (
        <LoadingSpinnerNav
          type="backandlogo"
          hidehome
          hidefriends
          hide="profile"
        />
      );
    }

    return (
      <React.Fragment>
        <Header type="backandlogo" hidehome hidefriends hide="profile" />
        <ReloadModal version={version} />

        <div className="row justify-content-center mx-auto appearslowly">
          <div className="col-lg-6 text-center px-0">
            <main className="container text-center">
              <h4 className="text-left pt-4 mt-2 ml-2 text-primary">
                {friends.length
                  ? `Your Friends (${friends.length})`
                  : `No Friends Yet`}
              </h4>
              <div className="py-4 pointer text-center">
                {friends &&
                  friends.map((entry, index) => {
                    return (
                      <FriendList
                        key={index}
                        entry={entry}
                        action="viewstats"
                        clickHandler={() => this.processUserView(entry)}
                      />
                    );
                  })}
              </div>
              {friends.length === 0 && <NoFriends />}
              {this.viewUserModal()}
              {friends.length > 5 && (
                <>
                  <ScrollToTop />
                  <LinkButton type="link" link="/home" label="Back to Home" />
                </>
              )}
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FriendsPage;
