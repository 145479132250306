import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Typist from "react-typist";

function SamplePrivateNotes({ entries }) {
  let thanks = [
    "My staff is very dedicated",
    "The table at the foodcourt is clean and I found a seat🍽️",
    "I found an empty spot in the parking lot🚗",
    "The toilet is clean and smells good🚽",
    "Thank you that my colleague helped me reserved a seat for the meeting🙏",
    "I am humbled and grateful for the strength to ask for help because I am struggling internally🙏",
    "Thank you God that my siblings volunteered to help me with expenses for our mother🙏",
    "I am blessed with my loving kids🙏",
    "My heart if filled with joy when my friends don't judged me of my mistakes anymore🙏",
    "My heart humbled when my family called me and I said sorry to them🙏",
    "I am filled with grace when I am able to hold my grandchild for the first time🙏",
    "I am filled with grace when my kids brought her family to our house after years of silence🙏",
    "I am glad after many decades, I have reconciled with my family!👨‍👩‍👧‍👦",
    "I am very grateful that I have talked to my sister after 40 years!!!👩🏻👩🏻",
    "I am thankful to see a young man helping an old lady to cross the street👵🏻👨🏻",
    "I am thankful to see a young student offering the seat to an old man👴🏻👩🏻",
    "I feel grateful for the honest people to help me grow🙏",
    "I am grateful for the time you showed me my job🌟",
    "I am grateful for helping me to be a better person👍",
    "I'm blessed for my understanding wife👩‍❤️‍👨",
    "Thank you God for my understanding husband👩‍❤️‍👨",
    "Thank you Lord for my understanding kids👨‍👩‍👧‍👦",
    "I feel blessed for my understanding girlfriend👩‍❤️‍👨",
    "I'm very thankful for my understanding boyfriend👩‍❤️‍👨",
    "I'm grateful for the energy that I am able to finish my work💪",
    "I'm grateful for the time that I have to spend with my family❤️",
    "I'm grateful for the delicious burger🍔.",
    "I'm grateful for the time that I have to rest😴",
    "Thank you for the chance to visit my grandparents👴🏻👵🏻",
    "I'm thankful for the chance to visit my parents😍",
    "Thank God that I was able to call my brother👨🏻",
    "Thank you for the steak 🥩 prepared by my wife😘",
    "I am grateful that my husband throw the garbage every night🗑️",
    "Thank you Lord for the ability to work👩🏻‍💻 from home",
    "I am grateful for the time that I have to play with my kids👦🏻👧🏻",
    "I am grateful for the time that I have to play with my dog🐶",
    "Thank you Lord for the save evening walk🚶🏻",
    "I am grateful for the nice people that I met today👩🏻‍🤝‍👩🏻",
    "I am grateful for the nice smiling people that I met at Church today🙏",
    "I'm thankful for the wonderful friends that can bounce ideas with me🙏🙏🙏",
    "I am grateful for the wonderful and meaningful conversation I had with my wife🙏🙏🙏",
    "I am grateful for the wonderful and meaningful conversation I had with parents🙏🙏🙏",
    "Thank you Lord that I was able to take the bus at the right time🚌",
    "Thank you Lord that I was able to take the train at the right time🚆",
    "I'm grateful that I got the time to read a book📚",
    "I'm grateful for the fun movie marathon with my wife🎬",
    "Thank you Lord for the uninterrupted sleep😴",
    "Thank you Lord for the time that I have to pray🙏",
    "Thank you Lord for the time that I have to meditate🧘🏻",
    "Thank you Lord for the fresh air that I breathe🌬️",
  ];

  // Randomize the thanks array
  thanks.sort(() => Math.random() - 0.5);

  if (entries > 0) {
    thanks = thanks.slice(0, entries);
  }

  return (
    <div>
      <h2 className="my-4 text-center">
        Count your blessings, big or small 🥳
      </h2>
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 270: 1, 350: 2, 750: 3, 800: 4, 900: 6 }}>
        <Masonry columnsCount={3} gutter="12px">
          {thanks.map((t, i) => {
            let count = i;
            const color = [
              "alert-primary",
              "alert-success",
              "alert-danger",
              "alert-warning",
              "alert-info",
              "alert-secondary",
            ];
            if (count > 5) count = count % 5;
            const randomColor = color[count];
            return (
              <div
                className={`alert pointer card ${randomColor} shadow roundcornernocolor zoom p-0 m-1`}
                key={i}>
                <div className="card-body">
                  <span className="card-text">
                    <Typist cursor={{ show: false }}>
                      {t.split("\n").map((str, id) => (
                        <span key={id}>
                          {str}
                          <br />
                        </span>
                      ))}
                    </Typist>
                  </span>
                </div>
              </div>
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
}

export default SamplePrivateNotes;
