import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Header from "../../common/header";
import { imagesGallery } from "../component/images";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
// import ReloadBar from "../../common/reloadBar";
import { getLatestVersion } from "../../../services/getversion";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import DownloadApp from "../../common/downloadApp";
import Footer from "../../common/footer";
import CarouselQuotes from "../../common/carouselQuotes";
import AuthSuggestBlock from "../../common/authSuggestBlock";
import MissionBlock from "../../common/missionBlock";
import { enableWebVersion } from "../../../services/settings";
import ReloadModal from "../../common/reloadModal";

function Quotes() {
  const [index, setIndex] = useState(-1);
  const [version, setVersion] = useState("");
  const currentImage = imagesGallery[index];
  const nextIndex = (index + 1) % imagesGallery.length;
  const nextImage = imagesGallery[nextIndex] || currentImage;
  const prevIndex = (index + imagesGallery.length - 1) % imagesGallery.length;
  const prevImage = imagesGallery[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    const getDatas = async () => {
      const result = await getLatestVersion();
      setVersion(result);
    };
    getDatas();
  }, []);

  if (version === "") {
    return (
      <LoadingSpinnerNav
        hidehome={true}
        hide="profile"
        hidefriends={true}
        hidenotifications={true}
      />
    );
  }

  return (
    <div>
      <Header hide="profile" />
      <ReloadModal version={version} />

      <div className="row justify-content-center mx-0">
        <div className="col-lg-8 pt-4">
          <DownloadApp type="simple" />
          <div className="my-4 "></div>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
            <Masonry columnsCount={3} gutter="15px">
              {imagesGallery.map((image, i) => (
                <img
                  className="pointer roundcornernocolor shadow zoom"
                  onClick={() => handleClick(i)}
                  key={i}
                  src={image.src}
                  style={{ width: "100%", display: "block" }}
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
          {!!currentImage && (
            <Lightbox
              mainSrc={currentImage.original}
              imageTitle={currentImage.caption}
              mainSrcThumbnail={currentImage.src}
              nextSrc={nextImage.original}
              nextSrcThumbnail={nextImage.src}
              prevSrc={prevImage.original}
              prevSrcThumbnail={prevImage.src}
              onCloseRequest={handleClose}
              onMovePrevRequest={handleMovePrev}
              onMoveNextRequest={handleMoveNext}
            />
          )}
          <div className="text-center pt-4 mt-4">
            <MissionBlock />
            <div className="py-4">
              <hr />
            </div>
            <CarouselQuotes />
            <div className="my-4 py-4">
              <hr />
            </div>
            {enableWebVersion() ? (
              <AuthSuggestBlock />
            ) : (
              <div className="pt-3 pb-2">
                <DownloadApp type="download" css="py-4" size={200} />
              </div>
            )}
          </div>
          <div className="my-4 py-4">
            <hr />
          </div>
        </div>
      </div>

      <div className=" text-center">
        <Footer />
      </div>
    </div>
  );
}

export default Quotes;
