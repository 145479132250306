import React, { Component } from "react";
import Header from "../../common/header";
import fire from "../../../services/fire";
import { convertToDate, mapToDay } from "../../../utils/utils";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import { getCurrentUser } from "../../../services/user";

class BasicStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noteFreq: [],
      noteList: [],
      userFreq: [],
      loading: true,
    };
  }

  async componentDidMount() {
    //check if allowed
    const user = await getCurrentUser();
    if (!user) {
      this.props.history.push("/welcome");
      return;
    }
    if (
      user.uid === "mTdWaLm65nSWzXU1cHE4PmVmUPm1" ||
      user.uid === "VYj16iAHJJh5WVdimE5BfTvluN63"
    ) {
      await this.getStatsData();
    } else {
      this.props.history.push("/home");
      return;
    }
  }

  async getStatsData() {
    //retrieve data from firestore

    try {
      fire
        .firestore()
        .collection("notes")
        .orderBy("created", "desc")
        .limit(5000)
        .get()
        .then(async (querySnapshot) => {
          const list = [];
          const noteFreq = [];
          const userFreq = [];
          const dailyUsers = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            const date = convertToDate(data.created);
            const authorid = data.authorid;
            const tempId = authorid.substr(0, 6);
            const compId = `${date}*${tempId}`;
            list.push({ ...data, datestr: date });
            //create noteFreq Tab
            if (noteFreq[date]) {
              noteFreq[date]++;
            } else {
              noteFreq[date] = 1;
            }
            //create dailyUsers Tab
            if (dailyUsers[compId]) {
              dailyUsers[compId]++;
            } else {
              dailyUsers[compId] = 1;
            }
            //create userFreq Tab
            if (userFreq[tempId]) {
              userFreq[tempId]++;
            } else {
              userFreq[tempId] = 1;
            }
          });

          if (list.length) {
            // console.log("List: ", list);
          }

          console.log(dailyUsers);

          const usersDaily = [];

          for (const property in dailyUsers) {
            // console.log(`${property}: ${dailyUsers[property]}`);
            const idx = property.indexOf("*");
            const day = property.substring(0, idx);
            // const id = property.substring(idx + 1, property.length);
            // console.log(`${id} ${day}`);
            //create userFreq Tab
            if (usersDaily[day]) {
              usersDaily[day]++;
            } else {
              usersDaily[day] = 1;
            }
          }
          //sort usersDaily based on the key. the key format is 2023-5-29. so we can sort by key by converting it to timestamp and display from oldest to latest
          const sortedUsersDaily = Object.keys(usersDaily)
            .sort((a, b) => {
              return new Date(a) - new Date(b);
            })
            .reduce((obj, key) => {
              obj[key] = usersDaily[key];
              return obj;
            }, {});
          console.log("Sorted Daily Users...");
          console.log(sortedUsersDaily);
          //this mutates the key to add the equivalent day of the week. ex. 2023-5-29 becomes 2023-5-29 (Mon)
          for (const property in sortedUsersDaily) {
            const date = new Date(property);
            const day = date.getDay();

            const dayStr = mapToDay(day);
            sortedUsersDaily[`${property} (${dayStr})`] =
              sortedUsersDaily[property];
            delete sortedUsersDaily[property];
          }
          const sortedNoteFreq = Object.keys(noteFreq)
            .sort((a, b) => {
              return new Date(a) - new Date(b);
            })
            .reduce((obj, key) => {
              obj[key] = noteFreq[key];
              return obj;
            }, {});
          console.log("Sorted Notes Freq...");
          console.log(sortedNoteFreq);
          console.log("Daily Users...");
          console.log(usersDaily);
          //sort usersDaily based on
          console.log("Notes Freq...");
          console.log(noteFreq);
          console.log("User Freq...");
          console.log(userFreq);
          //find anything with lord
          const filtered = list.filter((item) => {
            return (
              item.content.toLowerCase().includes("lord") ||
              item.content.toLowerCase().includes("god")
            );
          });
          console.log(filtered);
          this.setState({
            filtered: filtered,
            loading: false,
            noteFreq: noteFreq,
            noteList: list,
            userFreq: userFreq,
          });
        });
    } catch (error) {
      console.error("Notes not yet created");
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, filtered } = this.state;
    if (loading === true) {
      return <LoadingSpinnerNav hide="profile" />;
    }

    return (
      <React.Fragment>
        <Header hide="profile" />
        <div className="row justify-content-start  mx-4">
          {/* <h1 className="pb-3 pt-4 px-0">Basic Stats</h1> */}
          {/* <h3>Daily Notes</h3> */}
          {/* {noteFreq.map((entry, index) => {
                return <div id={index}>{`${entry} ${index}`}</div>;
              })} */}
          {/* {filtered.map((entry, index) => {
            return <div key={index}>{`${index}: ${entry.content}`}</div>;
          })} */}
          {/* <h3>Daily Users</h3> */}
        </div>
      </React.Fragment>
    );
  }
}

export default BasicStats;
