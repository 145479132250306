import React, { Component } from "react";
import Header from "../../common/header";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import BottomBar from "../../common/bottomBar";
import StorefrontTwoToneIcon from "@material-ui/icons/StorefrontTwoTone";
import fire from "../../../services/fire";

class PublicHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state: "ready",
      user: this.props.user,
      loading: true,
      id: this.props.match.params.id,
    };
  }

  async componentDidMount() {
    const id = this.state.id;
    //get the uid
    try {
      const db = fire.firestore();

      const doc = await db
        .collection("users")
        .where("storelink", "==", id)
        .get();

      let storelink, storename, storecontact, products, bigpic;
      doc.forEach((item) => {
        let data = item.data();
        storelink = data.storelink;
        storename = data.storename;
        storecontact = data.storecontact;
        products = data.products;
        bigpic = data.bigpic;
      });
      if (!storelink) {
        this.props.history.push("/home");
        return;
      }
      this.setState({
        inputdata: {
          storelink,
          storename,
          storecontact,
          products,
          bigpic,
        },
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
    window.scrollTo(0, 0);
  }

  handleButtonClick = () => {
    const { inputdata } = this.state;
    window.open(`https://m.me/${inputdata.storecontact}`, "_blank");
  };

  displayImages = (list) => {
    let style = "col-4 text-center p-1  zoom pointer";
    if (list.length === 1) {
      style = "col-lg-8 text-center py-3 mx-auto zoom pointer";
    } else {
      if (list.length === 2) {
        style = "col-6 text-center py-3 zoom pointer";
      }
    }
    return list.map((item) => (
      <div key={item.timestamp} className={`${style}`}>
        <Link to={`/v/${item.id}`}>
          <img alt="" className="responsive" src={item.image} />
        </Link>
      </div>
    ));
  };

  getDisplayContent = () => {
    const { state, inputdata } = this.state;
    const photoUrl = inputdata.bigpic;
    let display = null;
    if (state === "ready") {
      display = (
        <>
          {!photoUrl && (
            <StorefrontTwoToneIcon
              className="text-primary"
              style={{ fontSize: "100px" }}
            />
          )}
          {photoUrl && (
            <Image
              src={photoUrl}
              width="100px"
              height="100px"
              alt=""
              roundedCircle
            />
          )}

          <h3 className="py-0 mb-0">{inputdata.storename}</h3>
          {inputdata.storelink && (
            <Link className="font-weight-normal" to={`/${inputdata.storelink}`}>
              palitna.com/{inputdata.storelink}
            </Link>
          )}
          <div className="clearfix py-3"></div>

          <div className="row ">
            {inputdata.products && this.displayImages(inputdata.products)}
          </div>
          <BottomBar
            label="Message to Order"
            onclick={this.handleButtonClick}
          />
        </>
      );
    }
    return display;
  };

  render() {
    const { loading, user } = this.state;
    const hide = "profile";
    let hidehome = true;
    if (user) {
      hidehome = false;
    }
    if (loading === true) {
      return <LoadingSpinnerNav hidehome={hidehome} hide={hide} />;
    }
    return (
      <React.Fragment>
        <Header hide={hide} hidehome={hidehome} />
        <div className="row justify-content-center mx-0">
          <div className="col-lg-8 px-1">
            <main className="container-fluid text-center pt-4">
              {this.getDisplayContent()}
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PublicHome;
