import React from "react";
import { Image, Media } from "react-bootstrap";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import { convertFireStorageUrl } from "../../../utils/url";

function NotificationList({ entry, clickHandler }) {
  const type = entry.type;
  let action;
  if (type === "notereply") {
    action = <span>{` has replied to your `}ThankyNote</span>;
  } else if (type === "notesent") {
    action = <span>{` has sent you a `}ThankyNote</span>;
  } else if (type === "notesaved") {
    action = <span>{` has saved your `}ThankyNote</span>;
  }
  const unread = entry.open === false;
  // console.log({ entry });
  return (
    <div
      className={`${
        unread ? "clickableentryenable" : "clickableentry"
      } reactiveiconlite py-2`}
      onClick={() => clickHandler(entry)}>
      <Media className="my-1">
        <div className="mx-auto text-center py-2 px-2">
          {!entry?.senderimagelink && (
            <AccountCircleTwoToneIcon
              className="text-primary"
              style={{ fontSize: "30px" }}
            />
          )}
          {entry?.senderimagelink && (
            <Image
              src={convertFireStorageUrl(entry?.senderimagelink)}
              width="30px"
              height="30px"
              alt=""
              roundedCircle
              style={{ zIndex: "1" }}
            />
          )}
          {unread && (
            <div
              className="mx-auto"
              style={{ opacity: "0.8" }}
              id="redcircledot"></div>
          )}
        </div>
        <Media.Body className="text-left reactiveiconlite pr-2">
          <div className="py-2">
            <div className="text-left text-dark twolineentry">
              <b>{entry.sendername}</b> {action}.
            </div>

            <div className="standardfontsize mt-2 text-muted text-left twolineentry">
              {entry.content.substring(0, 60)}...
            </div>
          </div>
          <div className="clearfix"></div>
          {unread && (
            <div className="float-right">
              <button
                className="mt-1 mr-2 mb-2 btn btn-primary btn-sm font-weight-bold"
                onClick={() => clickHandler(entry)}>
                Open
              </button>
            </div>
          )}
        </Media.Body>
      </Media>
    </div>
  );
}

export default NotificationList;
