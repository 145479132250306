import React from "react";
import { Link } from "react-router-dom";
import DownloadApp from "./downloadApp";

function AuthSuggestBlock({ messageid = "" }) {
  return (
    <div className="pt-0">
      <span role="img" aria-label="heart" style={{ fontSize: "80px" }}>
        ❤️
      </span>
      <h2 className="text-primary mb-4 pb-4">
        Start and End Your Day with Gratitude
      </h2>
      <div style={{ width: "280px" }} className="mx-auto text-center">
        <div className="mt-4 mb-0">
          <div className="">
            <Link
              className="font-weight-bold btn btn-primary btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
              to={`/register/${messageid}`}>
              Start now, it's FREE
            </Link>
          </div>
          <Link
            className="font-weight-bold btn btn-outline-primary btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
            to={`/login/${messageid}`}>
            Log In
          </Link>
          <Link
            className="font-weight-bold btn btn-link btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
            to={`/forgot/${messageid}`}>
            Forgot Password?
          </Link>
          <div className="mb-3">OR</div>
          <DownloadApp type="download" />
        </div>
      </div>
    </div>
  );
}

export default AuthSuggestBlock;
