import React from "react";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { Link } from "react-router-dom";

const SystemLogo = ({ messageid = "" }) => {
  return (
    <div className="navbar navbar-light text-center flex-column pointer">
      <Link to={`/welcome/${messageid}`} className="forcenoline">
        <h1 className="mb-0 pb-0 font-weight-bold text-primary pointer">
          <div className="py-2">
            <PostAddIcon className="logo-big" />
          </div>
          THANKY<span className="text-muted">NOTE</span>
        </h1>
      </Link>
      {/* <p className="averagefontsize text-muted">
        Designed for people who wants to practice daily gratitude.
      </p> */}
    </div>
    // <nav className="navbar navbar-light text-center flex-column">
    //   <a className="navbar-brand mx-auto" href="# ">
    //     <h1 className="mb-0 pb-0 font-weight-bold text-primary">
    //       <div className="py-2">
    //         <PostAddIcon className="logo-big" />
    //       </div>
    //       THANKYNOTE
    //     </h1>
    //   </a>
    //   <p className="averagefontsize">Spread gratitude around the world.</p>
    // </nav>
  );
};

export default SystemLogo;
