import React from "react";
import { Table } from "react-bootstrap";

function UserEntryTable({ item, handleClick }) {
  console.log(item);
  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>User ID</th>
            <th>Fullname</th>
            <th>Email</th>
            <th>Big Pic</th>
            <th>Country</th>
            <th>Has Generated</th>
            <th>Init</th>
            <th>Last Entry</th>
            <th>Recv Click</th>
            <th>Sent Click</th>
            <th>Small Pic</th>
            <th>Streak</th>
            <th>Tokens</th>
            <th>Total Notes</th>
            <th>Total Received</th>
            <th>Total Sent</th>
            <th>Voices</th>
          </tr>
        </thead>
        <tbody>
          {item.map((i, index) => {
            if (i?.test) {
              return null;
            }
            let bigpic = null;

            let fill = [];
            if (i.hasOwnProperty("bigpic")) {
              bigpic =
                i.bigpic.length > 0 ? (
                  <img src={i?.bigpic} width={50} />
                ) : (
                  "No big pic"
                );
            } else {
              bigpic = "";
              fill["bigpic"] = "alert-danger";
            }
            let country = null;

            if (i.hasOwnProperty("country")) {
              country = i?.country;
              fill["country"] = "";
            } else {
              country = "";
              fill["country"] = "alert-danger";
            }
            let hasgeneratedfriends = null;
            if (i.hasOwnProperty("hasgeneratedfriends")) {
              hasgeneratedfriends = i?.hasgeneratedfriends ? "T" : "F";
              fill["hasgeneratedfriends"] = "";
            } else {
              hasgeneratedfriends = "";
              fill["hasgeneratedfriends"] = "alert-danger";
            }
            let init = null;

            if (i.hasOwnProperty("init")) {
              init = i?.init ? "T" : "F";
              fill["init"] = "";
            } else {
              init = "";
              fill["init"] = "alert-danger";
            }
            let lastentry = null;
            if (i.hasOwnProperty("lastentry")) {
              lastentry = i?.lastentry;
              fill["lastentry"] = "";
            } else {
              lastentry = "";
              fill["lastentry"] = "alert-danger";
            }
            let totalnotes = null;
            if (i.hasOwnProperty("totalnotes")) {
              totalnotes = i?.totalnotes;
              fill["totalnotes"] = "";
            } else {
              totalnotes = "";
              fill["totalnotes"] = "alert-danger";
            }
            let totalreceived = null;
            if (i.hasOwnProperty("totalreceived")) {
              totalreceived = i?.totalreceived;
              fill["totalreceived"] = "";
            } else {
              totalreceived = "";
              fill["totalreceived"] = "alert-danger";
            }
            let totalsent = null;
            if (i.hasOwnProperty("totalsent")) {
              totalsent = i?.totalsent;
              fill["totalsent"] = "";
            } else {
              totalsent = "";
              fill["totalsent"] = "alert-danger";
            }
            let smallpic = null;
            if (i.hasOwnProperty("smallpic")) {
              smallpic = i?.smallpic ? (
                <img src={i?.smallpic} width={50} />
              ) : (
                "No small pic"
              );
            } else {
              smallpic = "";
              fill["smallpic"] = "alert-danger";
            }
            return (
              <tr key={i.userid}>
                <td>{index + 1}</td>
                <td>{i?.userid}</td>
                <td>{i?.fullname}</td>
                <td className="pointer" onClick={() => handleClick(i)}>
                  {i?.email}
                </td>
                <td className={`${fill["bigpic"]}`}>{bigpic}</td>
                <td className={`${fill["country"]}`}>{country}</td>
                <td className={`${fill["hasgeneratedfriends"]}`}>
                  {hasgeneratedfriends}
                </td>
                <td className={`${fill["init"]}`}>{init}</td>
                <td className={`${fill["lastentry"]}`}>{lastentry}</td>
                <td>{i?.recvclick ? "T" : "F"}</td>
                <td>{i?.sentclick ? "T" : "F"}</td>
                <td className={`${fill["smallpic"]}`}>{smallpic}</td>
                <td>{i?.streak && i?.streak?.length > 0 ? "T" : "F"}</td>
                <td>{i?.tokens && i?.tokens?.length > 0 ? "T" : "F"}</td>
                <td className={`${fill["totalnotes"]}`}>{totalnotes}</td>
                <td className={`${fill["totalreceived"]}`}>{totalreceived}</td>
                <td className={`${fill["totalsent"]}`}>{totalsent}</td>
                <td>{i?.voices?.substring(0, 4)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default UserEntryTable;
