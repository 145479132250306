import React from "react";
import { Carousel } from "react-bootstrap";

function CarouselQuotes() {
  return (
    <Carousel indicators={false} controls={true}>
      <Carousel.Item>
        <div
          className="d-block w-100"
          style={{
            paddingLeft: "40px",
            paddingRight: "40px",
          }}>
          <span role="img" aria-label="star" style={{ fontSize: "80px" }}>
            🌟
          </span>
          <h2 className="text-dark font-weight-light pb-4">
            Be grateful everyday.
          </h2>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div
          className="d-block w-100"
          style={{
            paddingLeft: "40px",
            paddingRight: "40px",
          }}>
          <span role="img" aria-label="party" style={{ fontSize: "80px" }}>
            🎉
          </span>

          <h2 className="text-dark font-weight-light pb-4">
            Count your blessings.
          </h2>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div
          className="d-block w-100"
          style={{
            paddingLeft: "40px",
            paddingRight: "40px",
          }}>
          <span role="img" aria-label="smiley" style={{ fontSize: "80px" }}>
            😊
          </span>
          <h2 className="text-dark font-weight-light pb-4">
            Practice daily gratitude.
          </h2>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselQuotes;
