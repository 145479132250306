import React, { useEffect, useState } from "react";
import { getLatestVersion } from "../../../services/getversion";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import Header from "../../common/header";
// import ReloadBar from "../../common/reloadBar";
import DownloadApp from "../../common/downloadApp";
import MissionBlock from "../../common/missionBlock";
import CarouselQuotes from "../../common/carouselQuotes";
import AuthSuggestBlock from "../../common/authSuggestBlock";
import Footer from "../../common/footer";
import Confetti from "react-confetti";
import { Link } from "react-router-dom";
import HomeBannerImage from "../../../img/homeimages/mockup.png";
import Boy from "../../../img/misc/boy.png";
import { getCurrentUserData } from "../../../services/user";
import fire from "../../../services/fire";
import ReloadModal from "../../common/reloadModal";

function Stats2023(props) {
  const [version, setVersion] = useState("");
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    const getDatas = async () => {
      const { id } = props.match.params;
      const result = await getLatestVersion();
      const data = await getCurrentUserData(id);
      // console.log(data);
      if (data) {
        setUser(data);
      }
      setVersion(result);
      //get all users from users firestore collection  and set users state
      // const users = await getAllUsers();
      //filter out test users
      // const filteredUsers = users.filter((user) => user.test !== true);

      // console.log(filteredUsers);
      // for (let i = 0; i < filteredUsers.length; i++) {
      //   const u = filteredUsers[i];
      //   //get all the notes per user from notes collection where authorid === filteredUsers.uid and created is between new Date("2023-01-01").getTime() and new Date("2023-12-31").getTime()
      //   const notesRef = fire.firestore().collection("notes");
      //   const start = new Date("2023-01-01T00:00:01").getTime();
      //   const end = new Date("2023-12-31T23:59:59").getTime();
      //   const snapshot = await notesRef
      //     .where("authorid", "==", u.userid)
      //     .where("created", ">=", start)
      //     .where("created", "<=", end)
      //     .get();
      //   const notes = snapshot.docs.map((doc) => doc.data());
      //   //save the total notes to the user collection
      //   // const userRef = fire.firestore().collection("users").doc(u.userid);
      //   // if (i === 1) {
      //   // await userRef.update({
      //   //   2022: notes.length,
      //   // });
      //   // }

      //   console.log(u.fullname, " = ", notes);
      // }

      // setUsers(filteredUsers);
    };
    getDatas();
  }, []);

  async function getAllUsers() {
    try {
      const usersRef = fire.firestore().collection("users");
      const snapshot = await usersRef.get();
      const users = snapshot.docs.map((doc) => doc.data());
      return users;
    } catch (error) {
      console.error("Error getting users:", error);
      return null;
    }
  }

  if (version === "") {
    return (
      <LoadingSpinnerNav
        hidehome={true}
        hide="profile"
        hidefriends={true}
        hidenotifications={true}
      />
    );
  }

  const DisplayGreet = () => {
    return (
      <div className="col-lg-8 pt-4">
        <h2 className="mt-4 text-center">
          Happy New Year {user && <div className="">{user?.fullname}!</div>}
        </h2>
        <div className="text-center">
          <img
            className="text-center"
            width={140}
            height={100}
            src={Boy}
            alt="Boy"
          />
        </div>
        {user && (
          <div className="my-4 text-center">
            <div className="defaultfontsize my-5 font-weight-bold">
              Continue to spread gratitude and positivity! 🙏
            </div>
            <div className="defaultfontsize my-5 font-weight-bold">
              We wish you and your family a very Happy New Year 2024! 🥳🎉
            </div>
          </div>
        )}
      </div>
    );
  };

  const Display2023 = () => {
    return (
      <div className="col-lg-8 pt-4">
        <h2 className="mt-4 text-center">
          Congratulations {user && <div className="">{user?.fullname}!</div>}
        </h2>
        <div className="text-center">
          <img
            className="text-center"
            width={140}
            height={100}
            src={Boy}
            alt="Boy"
          />
        </div>
        {user && (
          <div className="my-4 text-center">
            <div className="defaultfontsize mb-3 font-weight-bold">
              You have written ✍️
            </div>
            <div className="d-flex justify-content-center">
              <div className="alert alert-success roundcornernocolorbutton p-4">
                <h1 className="font-weight-bolder">{user["2023"]}</h1>
              </div>
            </div>
            <div className="defaultfontsize mb-3 font-weight-bold">
              Daily Gratitude Notes in 2023
            </div>
            <div className="defaultfontsize my-5 font-weight-bold">
              Continue to spread gratitude and positivity! 🙏
            </div>
            <div className="defaultfontsize my-5 font-weight-bold">
              Happy New Year 2024! 🥳🎉
            </div>
          </div>
        )}
      </div>
    );
  };

  const DisplayAll = () => {
    return (
      <div className="col-lg-8 pt-4">
        <h2 className="mt-4 text-center">
          Congratulations {user && <div className="">{user?.fullname}!</div>}
        </h2>
        <div className="text-center">
          <img
            className="text-center"
            width={140}
            height={100}
            src={Boy}
            alt="Boy"
          />
        </div>
        {user && (
          <div className="my-4 text-center">
            <div className="defaultfontsize mb-3 font-weight-bold">
              You have written ✍️
            </div>
            <div className="d-flex justify-content-center">
              <div className="alert alert-success roundcornernocolorbutton p-4">
                <h1 className="font-weight-bolder">{user["totalnotes"]}</h1>
              </div>
            </div>
            <div className="defaultfontsize mb-3 font-weight-bold">
              Daily Gratitude Notes
            </div>
            <div className="defaultfontsize my-5 font-weight-bold">
              Continue to spread gratitude and positivity! 🙏
            </div>
            <div className="defaultfontsize my-5 font-weight-bold">
              Happy New Year 2024! 🥳🎉
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Header hide="profile" />
      <ReloadModal version={version} />

      <Confetti recycle={false} opacity={1} gravity={0.1} />
      <div className="row justify-content-center mx-0">
        {user && user["2023"] > 0 && user["totalnotes"] > 0 && <Display2023 />}
        {user && user["2023"] === 0 && user["totalnotes"] > 0 && <DisplayAll />}
        {user && user["totalnotes"] === 0 && <DisplayGreet />}
      </div>
      <hr />
      {/* <div
        style={{
          width: "150px",
          height: "120px",
          backgroundColor: "#d4edda",
          borderRadius: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderColor: "#c3e6cb",
          color: "#155724",
          fontWeight: "bold",
          fontSize: "2rem",
        }}>
        5310
      </div>
      <hr /> */}
      <div className="px-4">{/* <SampleNotes /> */}</div>
      <div className="container col-lg-6 mt-4 pt-0">
        <div className="row align-items-center d-flex pt-0">
          <div className="text-center container h-50 pb-0 mb-0">
            <h2 className="pt-4">For Web and Android</h2>
            <div className="px-3 mb-4 responsive">
              <img
                className="responsive img-fluid"
                src={HomeBannerImage}
                alt="ThankyNote Home Page"
              />
            </div>
            <div className="pb-4">
              <div className="mt-4 mb-0">
                <div className="mb-4">
                  <Link
                    className="font-weight-bold btn-primary btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
                    to={`/register`}>
                    Try now, it's FREE
                  </Link>
                </div>
                <Link
                  className="font-weight-bold btn btn-outline-primary btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
                  to={`/login`}>
                  Log In
                </Link>
                <Link
                  className="font-weight-bold btn btn-link btn-lg btn-block px-4 my-4 roundcornernocolorbutton"
                  to={`/forgot`}>
                  Forgot Password?
                </Link>
                <div className="mb-3">OR</div>
                <DownloadApp type="download" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mx-0">
        <div className="col-lg-8 pt-4">
          <div className="text-center pt-4 mt-4">
            <MissionBlock />
            <div className="py-4">
              <hr />
            </div>
            <CarouselQuotes />
            <div className="my-4 py-4">
              <hr />
            </div>
            <AuthSuggestBlock />
          </div>
          <div className="my-4 py-4">
            <hr />
          </div>
        </div>
      </div>

      <div className=" text-center">
        <Footer />
      </div>
    </div>
  );
}

export default Stats2023;
